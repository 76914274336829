import clsx from 'clsx';
import { useStyles } from './style';

interface PropsType {
  tokenID?: any;
  onClick?: any;
  isSelected ?: boolean;
}

const StakingCard = ({ tokenID, onClick, isSelected }: PropsType) => {  
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, isSelected? 'selected':'')}  onClick={onClick}>
      <div className={classes.card_body}>
        {isSelected && <img src='/assets/icons/verified.svg' alt=""  className={classes.selectedIcon}/>}
        <div className={classes.nft_div}>
          <div className='img-div'>
            <img src={`${process.env.REACT_APP_IMG_BASEURL}${tokenID}.png`} alt="" />
          </div>
        </div>
        
        <div className={classes.state}>
          <div className="row_div">
            <p>#{tokenID}</p>
          </div>
          <div className="row_div">
            <h3>Angry Pitbull</h3>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default StakingCard;
