import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height : '100%',
    position : 'relative',
    flexDirection: 'row',
    paddingBottom :26,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingBottom : 0,
    },
  },

  container: {
    width: '100%',
    minHeight : '100vh',
    margin : 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize : '100%',
    backgroundRepeat : 'no-repeat',
    paddingTop : 80,
    [theme.breakpoints.down('sm')]: {
      paddingTop : 60,
    },
    
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom : 46,
    gridArea : 'auto',
    gap : 32,
    [theme.breakpoints.down('sm')]: {
      gap : 16,
      marginBottom : 16,
    },
    '& .icon': {
      width : 48,
      height : 48,
      borderRadius : 18,
      display: 'flex',
      alignItems: 'center',
      justifyContent : 'center',
      background : 'radial-gradient(112.5% 112.5% at 6.25% 0%, rgba(78, 255, 255, 1) 0%, rgba(151, 13, 216, 1) 100%)',
      position : 'relative',
      [theme.breakpoints.down('xs')]: {
      },
      '&:after': {
        content : "''",
        position :'absolute',
        background : '#1f274c',
        height : 'calc(100% - 2px)',
        width : 'calc(100% - 2px)',
        borderRadius : 18,
        left : 1,
        top : 1,
      },
      '& img': {
        zIndex : 1,
        [theme.breakpoints.down('xs')]: {
        },
      },
    },
    '& h1': {
      color : '#E2EBFB',
      fontSize: 48,
      fontWeight : 800,
      lineHeight : 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
      },
    },
  },
 
  top: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop : 24,
    [theme.breakpoints.down('sm')]: {
      marginTop : 0,
    },
    '& p': {
      color : '#75849D',
      fontSize: 12,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight : 1,
        color : ''
      },
    },
    
  },

  content: {
    width: '100%',
    height : '100%',
    margin : 0,
    display: 'flex',
    flexDirection: 'column',
    padding : '26px 0',
    position : 'relative',
    overflow : 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingBottom : 0,
    },

  },
  contentClassName : {
    borderRadius: 20,
    [theme.breakpoints.down('xs')]: {
      '& .MuiPaper-root': {
        margin : 20,
        borderRadius: 20,
      }
    },
    '& .MuiPaper-root': {
      borderRadius: 20,
      backgroundColor : '#313131',
    }
  },
  row : {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom : 24,
    gridArea : 'auto',
    gap : 24,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    '&.space_between': {
      justifyContent: 'space-between',
    },
    '& .avatar': {
      display: 'flex',
      alignItems: 'center',
      gridArea : 'auto',
      gap : 16,
      [theme.breakpoints.down('xs')]: {
        width : '100%',
        justifyContent: 'flex-start',
      },
      '& h3': {
        color : '#E2EBFB',
        fontSize: 19,
        fontWeight : 600,
        [theme.breakpoints.down('xs')]: {
          fontSize: 19,
        },
        '& span':{
          color : '#949494',
        }
      },
    },
    '& .address': {
      display: 'flex',
      alignItems: 'center',
      gridArea : 'auto',
      gap : 46,
      [theme.breakpoints.down('sm')]: {
        gap : 16,
      },
      [theme.breakpoints.down('xs')]: {
        gap : 16,
        width : '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      '& .w-fixed': {
        minWidth : 324,
      },
    },
    '& .country_select':{
      border : 'none',
      background: 'rgba(88, 96, 147, 0.16)',
      borderRadius : 30,
      minHeight : 56,
      minWidth : 188,
      [theme.breakpoints.down('sm')]: {
        gap : 16,
        minWidth : 150,
      },
      [theme.breakpoints.down('xs')]: {
        minHeight : 46,
        
        width : '100%',
      },
      '& .MuiSelect-select': {
        color : '#E2EBFB !important',
        minHeight: '20px !important',
        padding : '5px 40px 5px 20px !important',
        fontSize : 12,
        fontWeight : 600,
      },
    },
    '& .state_select':{
      border : '1px rgba(88, 96, 147, 0.16) solid',
      background: 'rgba(88, 96, 147, 0)',
      borderRadius : 30,
      minHeight : 56,
      minWidth : 188,
      [theme.breakpoints.down('sm')]: {
        gap : 16,
        minWidth : 150,
      },
      [theme.breakpoints.down('xs')]: {
        minHeight : 46,
        minWidth : 150,
        width : '100%',
      },
      '& .MuiSelect-select': {
        color : '#E2EBFB !important',
        minHeight: '20px !important',
        padding : '5px 40px 5px 20px !important',
        fontSize : 12,
        fontWeight : 600,
        
      },
    },
    '& .city_select':{
      border : '1px rgba(88, 96, 147, 0.16) dashed',
      background: 'rgba(88, 96, 147, 0)',
      borderRadius : 30,
      minHeight : 56,
      minWidth : 188,
      [theme.breakpoints.down('sm')]: {
        gap : 16,
        minWidth : 150,
      },
      [theme.breakpoints.down('xs')]: {
        minHeight : 46,
        minWidth : 150,
        width : '100%',
      },
      '& .MuiSelect-select': {
        color : '#E2EBFB !important',
        minHeight: '20px !important',
        padding : '5px 40px 5px 20px !important',
        fontSize : 12,
        fontWeight : 600,
      },
    },
    
    '& .address_div':{
      position : 'relative',
      paddingRight : 42,
      background: 'rgba(88, 96, 147, 0.16)',
      borderRadius : 50,
      display : 'flex',
      alignItems : 'center',
      [theme.breakpoints.down('xs')]: {
        width : '100%',
      },
      '& .icon':{
        border : 'none',
        '& input': {
          minWidth: 600,
          [theme.breakpoints.down('sm')]: {
            minWidth : '100%',
          },
          [theme.breakpoints.down('xs')]: {
            minWidth : '100%',
          },
        },
      },
      '& button': {
        position : 'absolute',
        right : 20,
        border : 'none',
        padding : 0,
        background : '#ffffff00',
        display : 'flex',
        alignItems : 'center',
        transition : 'all 0.3s ease',
        cursor : 'pointer',
        '&:hover':{
          filter : 'brightness(1.5)'
        }
      },
    },
    '& .btns': {
      display: 'flex',
      alignItems: 'center',
      gridArea : 'auto',
      gap : 46,
      marginRight : 0,
      marginLeft : 'auto',
      [theme.breakpoints.down('xs')]: {
        width : '100%',
        justifyContent: 'space-between',
      },
      '& button': {
        border : 'none',
        padding : '15px 20px',
        borderRadius : 16,
        fontSize : 12,
        transition : 'all 0.3s ease',
        cursor : 'pointer',
      },
      '& .cancel_btn': {
        color : '#75849D',
        background : '#ffffff00',
        border : '1px #75849D solid',
        '&:hover':{
          color : '#fff'
        }
      },
      '& .submit_btn': {
        background : 'radial-gradient(159.21% 161.46% at -14.02% -15.63%, #4EFFFF 0%, #970DD8 100%)',
        position : 'relative',
        '&:hover':{
          '&:after':{
            opacity : 0.3,
          }
        },
        '& p':{
          color : '#EEEEEE',
          zIndex : 1,
        },
        '&:after':{
          content : "''",
          background : '#1b192e',
          opacity : 0,
          position : 'absolute',
          top : 0,
          left : 0,
          width : '100%',
          height : '100%',
          borderRadius : 16,
          transition : 'all 0.3s ease',

        }
      },
    },
  },
  sub_title : {
    color : '#75849D',
    fontSize : 13,
    letterSpacing : '0.24em',
    fontWeight : 200,
    marginBottom : 40,
    [theme.breakpoints.down('xs')]: {
      
    },
    
  },
  modalContent : {
    background: '#313131',
    padding : 28,
    
    [theme.breakpoints.down('xs')]: {
      padding : 10,
    },
  },
  myModal : {
    width: 720,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width : '100%',
    },
    '& .top': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom : 24,
      position : 'relative',
      width : '100%',
      '& h2': {
        color : '#fff',
        fontSize: 20,
      },
      '& button': {
        color : '#fff',
        fontSize: 20,
        background : '#ffffff00',
        border : 'none',
        cursor : 'pointer',
        transition : 'all 0.3s ease',
        position : 'absolute',
        left : 0,
        '&:hover': {
          opacity : 0.7
        },
      },
    },
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      width : 270,
      '& .row': {
        marginBottom : 0,
        width : '100%',
        display: 'flex',
        // alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          marginBottom : 5,
        },
        '& button': {
          marginTop : 5,
          width : '100%',
          transition : 'all 0.3s ease',
          cursor : 'pointer',
          textTransform : 'capitalize',
        },
        '& h3': {
          color : '#fff',
          fontSize : 20,
          fontWeight : 400,
        },
      },

    },
  }
}));



export default useStyles;
