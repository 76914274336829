import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width : '100%',
    position: 'relative',
    backgroundRepeat : 'repeat',
    background: 'radial-gradient(104.37% 104.37% at 65.25% -3.46%, #E2DBFF 0%, #D5D6FF 100%)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

  },
  
  layoutContent: {
    width: '100%',
    boxSizing : 'border-box',
    padding :'92px 80px',
    display: 'flex',
    zIndex: 1,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding :'40px 40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding : 20,
    },
  },
  sideBar: {
    width: 92,
    padding: 26,
    position: 'relative',
    borderRadius : 40,
    background : '#121526',
    boxShadow: "0px 16px 60px #00000008",
    [theme.breakpoints.down('xs')]: {
      display : 'none',
    },
  },
  mainContent: {
    width: 'calc(100% - 92px)',
    borderRadius : 40,
    boxSizing : 'border-box',
    padding : '40px 80px',
    height : 'auto',
    margin : 0,
    position: 'relative',
    background : '#111A2D',
    backgroundSize : 'cover',
    // overflow : 'hidden',
    boxShadow : '0px 30px 40px #111A2D99',
    [theme.breakpoints.down('sm')]: {
      padding :'24px 32px',
      backgroundPosition : 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      borderRadius : 20,
      padding :'24px 16px',
    },
  },
  effect1: {
    position: 'absolute',
    width: '60vw',
    height: '60vw',
    background: '#F9FBFF',
    opacity: 0.64,
    filter: 'blur(333px)',
    left : '50%',
    top : '-30vw',
    transform: 'translate(-50%)',
    borderRadius : '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      padding: 0,
    },
  },
  effect2: {
    position: 'absolute',
    width: '20vw',
    height: '20vw',
    background: '#61FFE3',
    opacity: 0.64,
    filter: 'blur(166px)',
    left : 0,
    top : '50%',
    transform: 'translateY(-50%)',
    borderRadius : '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      padding: 0,
    },
  },
  effect3: {
    position: 'absolute',
    width: '20vw',
    height: '20vw',
    background: '#6167FF',
    opacity: 0.64,
    filter: 'blur(166px)',
    right : 0,
    top : '50%',
    transform: 'translateY(-50%)',
    borderRadius : '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      padding: 0,
    },
  },
  effect_group1: {
    [theme.breakpoints.down('xs')]: {
      display : 'none'
    },
    '& .bottom': {
      position: 'absolute',
      width: 290,
      height: 300,
      left : 40,
      bottom :  40,
      background: 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #FF3CE0 0%, #FF7020 100%)',
      transform: 'rotate(180deg)',
      borderRadius : '60px 40px',
      [theme.breakpoints.down('md')]: {
        left : 20,
        bottom :  20,
      },
    },
    '& .top': {
      position: 'absolute',
      width: 290,
      height: 300,
      right : 40,
      top :  40,
      background: 'linear-gradient(133.78deg, #4D74FF 1.75%, #33FFF3 103.5%)',
      borderRadius : '60px 40px',
      [theme.breakpoints.down('md')]: {
        right : 20,
        top :  20,
      },
    },
  },
  effect_group2: {
    [theme.breakpoints.down('xs')]: {
      display : 'none'
    },
    '& .bottom': {
      position: 'absolute',
      width: 290,
      height: 300,
      right : 40,
      bottom :  40,
      background: 'linear-gradient(133.78deg, #4D74FF 1.75%, #33FFF3 103.5%)',
      borderRadius : '40px 60px',
      [theme.breakpoints.down('sm')]: {
        right : 20,
        bottom :  20,
      },
    },
    '& .top': {
      position: 'absolute',
      width: 290,
      height: 300,
      left : 40,
      top :  40,
      background: 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #FF3CE0 0%, #FF7020 100%)',
      transform: 'rotate(180deg)',
      borderRadius : '40px 60px',
      [theme.breakpoints.down('sm')]: {
        left : 20,
        top :  20,
      },
    },
  },
  effect_group3: {
    [theme.breakpoints.down('xs')]: {
      display : 'none'
    },
    '& .bottom': {
      position: 'absolute',
      width: 290,
      height: 300,
      right : '10%',
      bottom :  40,
      background: 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #FF3CE0 0%, #FF7020 100%)',
      transform: 'rotate(180deg)',
      borderRadius : '40px',
      [theme.breakpoints.down('sm')]: {
        bottom :  20,
      },
    },
    '& .top': {
      position: 'absolute',
      width: 290,
      height: 300,
      left : '15%',
      top :  40,
      background: 'linear-gradient(133.78deg, #4D74FF 1.75%, #33FFF3 103.5%)',
      borderRadius : '40px',
      [theme.breakpoints.down('sm')]: {
        top :  20,
      },
    },
  },
  effect_group4: {
    [theme.breakpoints.down('xs')]: {
      display : 'none'
    },
    '& .bottom': {
      position: 'absolute',
      width: 290,
      height: 300,
      left : 40,
      bottom :  '10%',
      background: 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #FF3CE0 0%, #FF7020 100%)',
      transform: 'rotate(180deg)',
      borderRadius : '40px',
      [theme.breakpoints.down('sm')]: {
        left :  20,
      },
    },
    '& .top': {
      position: 'absolute',
      width: 290,
      height: 300,
      right : 40,
      top :  '10%',
      background: 'linear-gradient(133.78deg, #4D74FF 1.75%, #33FFF3 103.5%)',
      borderRadius : '40px',
      [theme.breakpoints.down('sm')]: {
        right :  20,
      },
    },
  },
  effect_group5: {
    [theme.breakpoints.down('xs')]: {
      display : 'none'
    },
    '& .bottom': {
      position: 'absolute',
      width: 290,
      height: 300,
      left : '15%',
      bottom :  40,
      background: 'linear-gradient(133.78deg, #4D74FF 1.75%, #33FFF3 103.5%)',
      borderRadius : '40px',
      [theme.breakpoints.down('sm')]: {
        bottom :  20,
      },
    },
    '& .top': {
      position: 'absolute',
      width: 290,
      height: 300,
      right : '15%',
      top :  40,
      background: 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #FF3CE0 0%, #FF7020 100%)',
      transform: 'rotate(180deg)',
      borderRadius : '40px',
      [theme.breakpoints.down('sm')]: {
        top :  20,
      },
    },
  },
}));
export default useStyles;
