import { useStyles } from './style';
import { useEffect, useState } from 'react';
import { custom_network } from 'utils';
import { useAccount, useNetwork } from 'wagmi';
import { fetchSigner } from '@wagmi/core';

import { getAllItemListingArray, getItemsBoughtByUser } from 'utils/contracts';

import MySelect from 'components/Forms/MySelect';
import Masonry from 'react-masonry-css';
import Loader from 'components/LoadingPage/Loader';
import CollectionCard from 'components/Cards/CollectionCard/CollectionCard';

const InventoryPage = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [imgCount, setImgCount] = useState(0)

  const [exception, setException] = useState(false)

  const onLoad = () => {
    setImgCount(imgCount + 1)
  }
  useEffect(() => {
    if (imgCount >= 1) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500);
    }
  }, [setIsLoading, imgCount]);

  const viewOption = [
    { label: 'All', value: 0 },
    { label: 'High Price', value: 1 },
    { label: 'Low Price', value: 2 },
  ]
  const [view, setView] = useState(viewOption[0].value);

  const rankingOption = [
    { label: 'Angry Pitbull', value: 0 },
    { label: 'Bully', value: 1 },
    { label: 'Trap Lord', value: 2 },
    { label: 'King Pit', value: 2 },
    { label: 'Cerberus', value: 2 },
    { label: 'Anubis', value: 2 },
    { label: 'Trap Lord', value: 2 },
    { label: 'Trap Lord', value: 2 },
  ]
  const [ranking, setRanking] = useState(rankingOption[0].value);
  const breakpointCompact = {
    default: 4,
    1840: 4,
    1440: 4,
    1280: 3,
    768: 2,
    450: 1,
  };


  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const [signer, setSigner] = useState(null);
  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id)) {
      fetchSigner()
        .then((result) => {
          if (result) {
            console.log('fetch signer:', result);
            setSigner(result);
          }
        })
        .catch((error) => {
          console.log('fetch signer error:', error);
          setSigner(null);
        })
    }
  }, [address, isConnected, chain]);

  const [itemListingArray, setItemListingArray] = useState([]);

  useEffect(() => {
    getAllItemListingArray(null)
      .then((result) => {
        if (result) {
          console.log('itemListingArray:', result);
          setItemListingArray(result);
        }
      })
      .catch((error) => {
        console.log('itemListingArray fetch error:', error);
        setItemListingArray([])
      })
  }, []);

  const [boughtInfo, setBoughtInfo] = useState({
    indexes: [],
    amounts: []
  });
  const exceptionArray = ["0xac20d26331e0d86e334b8912696dda623fa4a4cd",
    "0x12Fa2a14f4c81B14F01cbCe1b591BAcA6214021d",
    "0x564B9632ca2E607Fcf3B5bf2d90FbCbbA06174Bb",
    "0xD82e536Dce1CDd7Ebf18fa2FF224BB4dc68D4397",
    "0xb162fD6cd1B50E6D5d9aD277858AdDaE2a6878bD"]

  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id) && signer) {
      getItemsBoughtByUser(signer, address)
        .then((result) => {
          if (result) {
            console.log('fetch boughtInfo:', result);
            setBoughtInfo(result);
            const isInExceptionArray = exceptionArray.some(
              (exceptionAddress) =>
                exceptionAddress.toLowerCase() === address.toLowerCase()
            );
            if(isInExceptionArray){
              setException(true)
            }
          }
        })
        .catch((error) => {
          console.log('fetch boughtInfo error:', error);
          setBoughtInfo({
            indexes: [],
            amounts: []
          });
        });
    }
  }, [address, isConnected, chain, signer]);


  return (
    <>
      <Loader isLoading={isLoading} />

      <div className={classes.root}>
        <div className={`${classes.container}`}>
          <div className={classes.top}>
            <div className={classes.title}>
              <div className="icon">
                <img src="/assets/icons/icon_reward_01.svg" alt="" onLoad={onLoad} />
              </div>
              <h1>Inventory</h1>
            </div>
          </div>

          <div className={classes.content}>
            <div className={classes.filter}>
              <div className="tab_btns">
                <h4>Collect items through quests, whitelists and many other fun events!</h4>
              </div>
              <div className="select_div">
                <div className="col">
                  <h4>View</h4>
                  <MySelect
                    value={view}
                    options={viewOption}
                    onChange={setView}
                    className='filter_select'
                  />
                </div>
                <div className="col">
                  <h4>Ranking</h4>
                  <MySelect
                    value={ranking}
                    options={rankingOption}
                    onChange={setRanking}
                    className='filter_select'
                  />
                </div>
              </div>

            </div>
            {
              address && isConnected && chain && (chain.id === custom_network.id) && signer && itemListingArray && itemListingArray.length > 0 &&
              <Masonry
                breakpointCols={breakpointCompact}
                className={classes.masonry}
                columnClassName={classes.gridColumn}
              >
                {boughtInfo.indexes.map((itemIndex, index) => (
                  <CollectionCard
                    key={index}
                    item={itemListingArray[Number(itemIndex)]}
                    amount={Number(boughtInfo.amounts[index])}
                  />
                ))}
                {exception && (
                  <CollectionCard
                    key="exception"
                    item={itemListingArray[Number(3)]} // or the specific index for the exception item
                    amount={Number(1)} // specific amount for the exception item
                  />
                )}
              </Masonry>
            }

          </div>
        </div>
      </div>

    </>

  );
};

export default InventoryPage;
