import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height : '100%',
    position : 'relative',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    width: '100%',
    margin : 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize : '100%',
    backgroundRepeat : 'no-repeat',
    paddingTop : 80,
    [theme.breakpoints.down('sm')]: {
      paddingTop : 40,
    },
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom : 20,
    gridArea : 'auto',
    gap : 32,
    [theme.breakpoints.down('sm')]: {
      gap : 16,
    },
    '& .icon': {
      width : 48,
      height : 48,
      borderRadius : 18,
      border : '1px #6CA3FF solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent : 'center',
      [theme.breakpoints.down('xs')]: {
      },
      '& img': {
        [theme.breakpoints.down('xs')]: {
        },
      },
    },
    '& h1': {
      color : '#E2EBFB',
      fontSize: 48,
      fontWeight : 800,
      lineHeight : 1,
      [theme.breakpoints.down('xs')]: {
        fontSize: 32,
      },
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight : 'auto',
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexWrap : 'wrap',
    alignItems: 'center',
    paddingTop : 40,
    paddingBottom : 0,
    position : 'relative',
    gridArea : 'auto',
    gap : '64px 40px',
    [theme.breakpoints.down('sm')]: {
      paddingTop : 20,
      paddingBottom : 20,
      gap : '24px 40px',
    },
  },
  sub_title: {
    width: '100%',
    position : 'relative',
    display: 'flex',
    '& h2':{
      fontSize : 24,
      fontWeight : 200,
      letterSpacing : '0.24em',
      color : '#E2EBFB',
      opacity : 0.4,
      paddingLeft : 24,
    }
  },

  filter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent : 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& .tab_btns':{
      display: 'flex',
      alignItems: 'center',
      gridArea : 'auto',
      gap : 32,
      [theme.breakpoints.down('sm')]: {
        gap : 16,
        marginBottom : 24,
      },
      '& button':{
        fontSize : 12,
        fontWeight : 600,
        color : '#75849D',
        padding : '10px 20px',
        transition : 'all 0.3s ease',
        background : '#6CA3FF00',
        cursor : 'pointer',
        border : '1px #6CA3FF00 solid',
        borderRadius : 30,
        '&:hover':{
          background : '#6CA3FF11'
        }
      },
      '& .activeBtn':{
        color : '#E2EBFB',
        background : '#6CA3FF11',
        border : '1px #6CA3FF solid',
        '&:hover':{
          background : '#6CA3FF22'
        }
      },
    },
    '& .select_div':{
      display: 'flex',
      alignItems: 'center',
      gridArea : 'auto',
      gap : 32,
    },
    '& .col':{
      '& h4':{
        fontSize : 12,
        fontWeight : 600,
        color : '#75849D',
        opacity : 0.4,
      },
    },
  },
  masonry: {
    display: 'flex',
    width: '100%',
    gridArea : 'auto',
    gap : 'min(64px, 4vw)',
    
  },
  gridColumn: {
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100% !important',
    },
  },
}));



export default useStyles;
