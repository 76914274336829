import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height : '100%',
    position : 'relative',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    width: '100%',
    margin : 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize : '100%',
    backgroundRepeat : 'no-repeat',
    paddingTop : 80,
    [theme.breakpoints.down('sm')]: {
      paddingTop : 60,
    },
  },
  titlecontainer: {
    width: '100%',
    color : '#75849D',
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom : 20,
    gridArea : 'auto',
    gap : 32,
    [theme.breakpoints.down('sm')]: {
      gap : 16,
      marginBottom : 0,
    },
    '& .icon': {
      width : 48,
      height : 48,
      borderRadius : 18,
      border : '1px #6CA3FF solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent : 'center',
      [theme.breakpoints.down('xs')]: {
      },
      '& img': {
        [theme.breakpoints.down('xs')]: {
        },
      },
    },
    '& h1': {
      color : '#E2EBFB',
      fontSize: 48,
      fontWeight : 800,
      lineHeight : 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
      },
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight : 'auto',
      flexDirection : 'column',
    },
    '& button':{
      fontSize : 12,
      fontWeight : 600,
      color : '#75849D',
      padding : '10px 24px',
      transition : 'all 0.3s ease',
      background : '#ffffff00',
      cursor : 'pointer',
      border : 'none',
      borderRadius : 30,
      position : 'relative',
      overflow : 'hidden',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginRight : 0,
        marginLeft : 'auto',
      },
      '&:hover':{
        '&:before':{
          opacity : 0.7,
        },
        '& p':{
          color : '#fff',
        }
      },
      '&:before':{
        content : "''",
        position : 'absolute',
        width : '100%',
        height : '100%',
        top : 0,
        left : '0',
        transition : 'all 0.3s ease',
        background : 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #912D3F 0%, #FF4062 100%)'
      },
      '& p':{
        color : '#E2EBFB',
        zIndex : 1,
        fontSize : 16,
        transition : 'all 0.3s ease',
        [theme.breakpoints.down('xs')]: {
          fontSize : 12,
        },
      },
    },
  },

  
  content: {
    width: '100%',
    display: 'flex',
    flexWrap : 'wrap',
    alignItems: 'center',
    paddingTop : 40,
    paddingBottom : 0,
    position : 'relative',
    gridArea : 'auto',
    gap : '42px 40px',
    [theme.breakpoints.down('sm')]: {
      paddingTop : 20,
      paddingBottom : 20,
      gap : 24,
    },
  },
  select_btns: {
    width: '100%',
    display: 'flex',
    alignItems : 'center',
    gridArea : 'auto',
    gap : 24,
    '& button':{
      fontSize : 12,
      fontWeight : 600,
      color : '#75849D',
      border : 'none',
      background : '#ffffff00',
      transition : 'all 0.3s ease',
      cursor : 'pointer',
      display: 'flex',
      alignItems : 'center',
      '&:hover':{
        color : '#E2EBFB'
      },
      '& img':{
        marginRight : 8,
      }
    }
  },

  filter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent : 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection : 'column',
    },
    '& .tab_btns':{
      display: 'flex',
      alignItems: 'center',
      gridArea : 'auto',
      gap : 32,
      [theme.breakpoints.down('xs')]: {
        gap : 16,
        flexWrap : 'wrap',
        marginBottom : 16,
      },
      '& button':{
        fontSize : 12,
        fontWeight : 600,
        color : '#75849D',
        padding : '10px 20px',
        transition : 'all 0.3s ease',
        background : '#6CA3FF00',
        // background : 'radial-gradient(98.75% 98.75% at 3.75% 1.25%, #7351F8 0%, #5039AC 100%)',
        cursor : 'pointer',
        border : 'none',
        borderRadius : 30,
        position : 'relative',
        overflow : 'hidden',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          padding : '10px 10px',
        },
        '&:hover':{
          '&:before':{
            opacity : 0.7,
          },
        },
        '&:before':{
          content : "''",
          position : 'absolute',
          width : '100%',
          height : '100%',
          top : 0,
          left : '0',
          transition : 'all 0.3s ease',
          background : 'radial-gradient(98.75% 98.75% at 3.75% 1.25%, #7351F8 0%, #5039AC 100%)',
        },
        '& p':{
          color : '#E2EBFB',
          zIndex : 1,
        },
      },
      // '& .activeBtn':{
      //   color : '#E2EBFB',
      //   background : 'radial-gradient(98.75% 98.75% at 3.75% 1.25%, #7351F8 0%, #5039AC 100%)',
      //   '&:hover':{
      //     filter : 'brightness(1.1) !important'
      //   }
      // },
    },
    '& .select_div':{
      display: 'flex',
      alignItems: 'center',
      gridArea : 'auto',
      gap : 32,
      [theme.breakpoints.down('xs')]: {
        marginRight : 0,
        marginLeft : 'auto',
      },
    },
    '& .col':{
      '& h4':{
        fontSize : 12,
        fontWeight : 600,
        color : '#75849D',
        opacity : 0.4,
      },
    },
  },
  masonry: {
    display: 'flex',
    width: '100%',
    gridArea : 'auto',
    gap : 'min(64px, 4vw)',

    [theme.breakpoints.down('sm')]: {
      gap : 16,
    },
  },
  modal_masonry: {
    display: 'flex',
    width: '100%',
    gridArea : 'auto',
    gap : 16,
    [theme.breakpoints.down('sm')]: {
      gap : 16,
    },
  },
  gridColumn: {
    // margin: theme.spacing(0, 3),
    [theme.breakpoints.down('sm')]: {
      // width: '100%',
      
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100% !important',
      margin: theme.spacing(0, 0.5),
    },
  },
  modalContent : {
    padding : '56px !important',
    overflow : 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding : '10px !important',
    },
    '& .MuiPaper-root' : {
      borderRadius : 56,
      background: 'radial-gradient(115.62% 190.53% at 11.12% -2.52%, #2E3560 0%, #181C32 100%)',
      [theme.breakpoints.down('xs')]: {
        borderRadius : 20,
      },
    }
  },
  myModal : {
    width: 720,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width : '70vw',
    },
    '& .top': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom : 24,
      position : 'relative',
      width : '100%',
      '& .avatar': {
        display: 'flex',
        alignItems: 'center',
        gridArea : 'auto',
        gap : 16,
        '& .img_div': {
          width : 72,
          height : 72,
          borderRadius : 72,
          display: 'flex',
          '& img' : {
            width : '100%',
            height : '100%',
          },
        },
        '& h3': {
          color : '#E2EBFB',
          fontSize: 24,
          fontWeight : 600,
        },
        '& p': {
          color : '#75849D',
          fontSize: 13,
          fontWeight : 600,
          opacity : 0.4,
        },
      },
      '& .title': {
        display: 'flex',
        alignItems: 'center',
        gridArea : 'auto',
        gap : 16,
        '& h3': {
          color : '#E2EBFB',
          fontSize: 24,
          fontWeight : 600,
        },
      },
      '& button': {
        color : '#E2EBFB',
        background : '#ffffff00',
        border : 'none',
        fontSize : 24,
        transition : 'all 0.3s ease',
        cursor : 'pointer',
        '&:hover': {
          color : '#fff',
        },
      },
    },
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      width : '100%',
      maxHeight : '60vh',
      overflowY : 'scroll',
      [theme.breakpoints.down('sm')]: {
        maxHeight : '65vh',
      },
      '& .row': {
        marginBottom : 24,
        width : '100%',
        display: 'flex',
        // alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          marginBottom : 5,
        },
        '& .line': {
          width : 2,
          height : 'auto',
          borderRight : '1px solid #75849D',
          margin : '0 35px',
          opacity : 0.2,
        },
        '& p': {
          color : '#75849D',
          fontSize: 12,
          fontWeight : 600,
          width : 'calc(100% - 72px)',
          paddingLeft : 16,
        },
      },
      '& ul': {
        width : '100%',
        display: 'flex',
        alignItems : 'flex-start',
        justifyContent : 'space-between',
        gridArea : 'auto',
        gap : 16,
        listStyle : 'none',
        padding : 0,
        [theme.breakpoints.down('sm')]: {
          flexWrap : 'wrap',
          gap : 8,
        },
        '& li': {
          display : 'flex',
          alignItems : 'center',
          justifyContent : 'center',
          flexDirection : 'column',
          maxWidth : 65,
          gridArea : 'auto',
          gap : 16, 
          [theme.breakpoints.down('sm')]: {
            width : '30%',
          },
          '& .circle': {
            borderRadius : '50%',
            boxShadow: '-33px 25px 17px rgba(14, 15, 32, 0.02), -19px 14px 14px rgba(14, 15, 32, 0.08), -8px 6px 11px rgba(14, 15, 32, 0.14), -2px 2px 6px rgba(14, 15, 32, 0.16), 0px 0px 0px rgba(14, 15, 32, 0.16)',
            marginBottom : 8,
          },
          '& h4': {
            color : '#E2EBFB',
            fontSize: 13,
            fontWeight : 600,
            textAlign : 'center',
          },
          '& p': {
            color : '#75849D',
            fontSize: 12,
            fontWeight : 600,
            width : '100%',
            opacity : 0.4,
            textAlign : 'center',
          },
        },
        '& .circle': {
          display: 'flex',
          justifyContent: 'center',
          alignItems:'center',
          '& img':{
            width : 20,
            height : 20,
          },
        },
        '& .day_1': {
          marginTop : 16,
          '& .circle': {
            width : 48,
            height : 48,
            background: 'linear-gradient(133.78deg, #4D74FF 1.75%, #33FFF3 103.5%)',
            
          },
        },
        '& .day_2': {
          marginTop : 81,
          '& .circle': {
            width : 56,
            height : 56,
            background: 'radial-gradient(98.75% 98.75% at 3.75% 1.25%, #7351F8 0%, #5039AC 100%)',
          },
        },
        '& .day_3': {
          marginTop : 70,
          '& .circle': {
            width : 24,
            height : 24,
            background: 'linear-gradient(99.97deg, #93E6DF 0%, #6A8E8B 105.69%)',
          },
        },
        '& .day_4': {
          marginTop : 16,
          '& .circle': {
            width : 48,
            height : 48,
            background: 'linear-gradient(152.59deg, #6CA3FF 7.11%, #1D6CF1 101.12%)',
          },
        },
        '& .day_5': {
          marginTop : 85,
          '& .circle': {
            width : 32,
            height : 32,
            background: 'radial-gradient(112.5% 112.5% at 6.25% 0%, #FFB84E 0%, #BE7F36 100%)',
          },
        },
        '& .day_6': {
          marginTop : 0,
          '& .circle': {
            width : 64,
            height : 64,
            background: 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #CA1DA7 0%, #EE703A 100%)',
          },
        },
      },
    },
    '& .bottom': {
      display: 'flex',
      alignItems: 'center',
      padding : 10,
      width : '100%',
      '& button':{
        fontSize : 12,
        fontWeight : 600,
        color : '#75849D',
        padding : '10px 24px',
        transition : 'all 0.3s ease',
        background : '#ffffff00',
        cursor : 'pointer',
        border : 'none',
        borderRadius : 30,
        position : 'relative',
        overflow : 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width : '100%',
        [theme.breakpoints.down('xs')]: {
        },
        '&:hover':{
          '&:before':{
            opacity : 0.7,
          },
          '& p':{
            color : '#fff',
          }
        },
        '&:before':{
          content : "''",
          position : 'absolute',
          width : '100%',
          height : '100%',
          top : 0,
          left : '0',
          transition : 'all 0.3s ease',
          background : 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #912D3F 0%, #FF4062 100%)'
        },
        '& p':{
          color : '#E2EBFB',
          zIndex : 1,
          fontSize : 16,
          transition : 'all 0.3s ease',
          [theme.breakpoints.down('xs')]: {
            fontSize : 12,
          },
        },
      },
    }
  }
}));



export default useStyles;
