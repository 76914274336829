import ReactDOM from "react-dom";

import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';


import App from './containers/App';
import reportWebVitals from './reportWebVitals';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-virtualized/styles.css';

import './style.scss';
import { RefreshContextProvider } from "context/RefreshContext";
import { custom_network } from "utils";


const { provider, webSocketProvider } = configureChains(
  [custom_network],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: process.env.REACT_APP_RPC,
      }),
    }),
  ],
);

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
});


ReactDOM.render(
  <RefreshContextProvider>
    <WagmiConfig client={client}>
      <ToastContainer />
      <App />
    </WagmiConfig>
  </RefreshContextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
