import clsx from 'clsx';
import Modal from 'components/modal';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { custom_network } from 'utils';
import { ethers } from 'ethers';

import { buyItem, getBoughtAmountOfItem, balanceOfToken } from 'utils/contracts';

import TextTimer from 'components/Timer/TextTimer';
import { useStyles } from './style';
interface PropsType {
  item?: any;
  itemIndex?: any;
  chain?: any;
  address?: any;
  isConnected?: any;
  signer?: any;
  nftBalance?: any;
  onClick?: any;
}

const WinnerCard = ({ item, itemIndex, chain, address, isConnected, signer, nftBalance, onClick }: PropsType) => {
  const classes = useStyles();
  const itemPrice = parseFloat(ethers.utils.formatUnits(String(item.price), 18));
  const [imgSrc, setImgSrc] = useState(item.image || '/assets/imgs/reward_01.png');

  const handleError = () => {
    setImgSrc('/assets/imgs/reward_01.png');
  };
  const [boughtAmountOfItem, setBoughtAmountOfItem] = useState(0);

  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id) && signer) {
      getBoughtAmountOfItem(signer, address, itemIndex)
        .then((result) => {
          if (result) {
            console.log('BoughtAmountOfItem:', result);
            setBoughtAmountOfItem(result);
          }
        })
        .catch((error) => {
          console.log('BoughtAmountOfItem fetch error:', error);
          setBoughtAmountOfItem(0)
        })
    }
  }, [address, isConnected, chain, signer, itemIndex]);

  const [startTime, setStartTime] = useState(false);
  const [showStakeModal, setShowStakeModal] = useState(false);

  const [buyQuantity, setBuyQuantity] = useState(1);

  const onBuyItem = () => {
    if (buyQuantity < 1) {
      toast.error('Please input amount to proceed.');
      return
    }
    if (buyQuantity > (Number(item.totalSupply) - Number(item.bought))) {
      toast.error('Supply is exhausted.');
      return
    }
    if (boughtAmountOfItem >= (Number(item.allowedPerNFT))) {
      toast.error('You purchased the maximum for the amount you staked.');
      return
    }
    console.log("item index", itemIndex)
    console.log("vs.")
    console.log("item id", item.id)

    if (Number(balanceOfToken) < (Number(item.price))) {
      console.log("Current Token Balance:", Number(balanceOfToken))
      console.log("Current Item Price:", Number(item.price))
      toast.error('You do not have enough tokens to purchase this items.');
    }
    const load_toast_id = toast.loading("Buying Item..."); // item index add
    buyItem(
      address,
      '0x884aFB9b2AD35e880E52b652db75cfE54287C289',
      itemIndex,
      buyQuantity,
      itemPrice,
      signer
    ).then(async (result) => {
      toast.dismiss(load_toast_id);
      if (result) {
        toast.success("Buying Items Success!");
        window.location.reload();
        return;
      } else {
        toast.error("Buying failed.");
        return;
      }
    }).catch(err => {
      console.log('error:', err);
      toast.dismiss(load_toast_id);
      toast.error("Failed Transaction");
      return;
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.card_body} onClick={onClick}>
        <div className={classes.img_div}>
          <img src={imgSrc} alt="" onError={handleError} />
        </div>

        <div className={clsx(classes.row, 'between')}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ color: '#51BD90' }}>Supply: </h4>
          </div>
          <h4>{`${Number(item.bought)} / ${Number(item.totalSupply)}`}</h4>
        </div>
        <div className={clsx(classes.row)}>
          <h2>{item.header}</h2>
        </div>
        <div className={clsx(classes.row)}>
          <span className={clsx(classes.desc)}>{item.description}</span>
        </div>

        {/* <div className={clsx(classes.row)}>
          <div className="progress_div">
            <div className="progress" style={{width : `${item?.progress}%`}}></div>
          </div>
        </div>
        <div className={clsx(classes.row)}>
          {!startTime ?
          <><h4 style={{opacity : 0.4, padding : 0}}>Raffle In : </h4> <TextTimer deadLine={item?.startAt} setTimeEnd = {setStartTime}/></>:
          <h4 style={{color : '#ffffff00'}}>#</h4>
          }
        </div> */}
        <div className={clsx(classes.row)}>
          <div className="circle" style={{ background: item?.type === 1 ? '#FF6A73' : '#75849D' }}></div>
          <h5 style={{ color: '#E2EBFB' }}>{Number(item.allowedPerNFT)}x Per Staked APC</h5>
        </div>

        <div className={clsx(classes.row)}>
          <div className="circle" style={{ background: item?.type === 1 ? '#FF6A73' : '#75849D' }}></div>
          <h5 style={{ color: '#E2EBFB' }}>Item Price: {itemPrice} $BONE</h5>
        </div>
        <div className={clsx(classes.row)}>
          <button onClick={() => {
            if (isConnected && chain && (chain.id === custom_network.id) && address) {
              setShowStakeModal(true)
            } else {
              toast.error('Please connect your wallet');
            }
          }}> <p>Buy Now </p> </button>
        </div>
      </div>
      {
        isConnected && chain && (chain.id === custom_network.id) && address &&
        <Modal
          show={showStakeModal}
          onHide={() => setShowStakeModal(false)}
          contentClass={classes.modalContent}
          contentClassName={classes.modalContent}
          maxWidth='md'
          children={
            <div className={classes.myModal}>
              <div className="top">
                <div className="title">
                  <h3>Buy Items</h3>
                </div>
                <button onClick={() => setShowStakeModal(false)}><i className="fas fa-times"></i></button>
              </div>
              <div className="content">
                <p>Price : <span>{itemPrice} $BONE</span></p>
                <p>Current Supply : <span>{((Number(item.totalSupply) - Number(item.bought)))}</span></p>
                <div className="inputContainer">
                  <p>Amount: </p>
                  <input type='number' onChange={(e) => { setBuyQuantity(Math.abs(Number(e.target.value))) }} value={buyQuantity} />
                </div>
                <p>You will pay : <span>{itemPrice * buyQuantity} $BONE</span></p>
              </div>
              <div className="bottom">
                <button onClick={onBuyItem}><p>Confirm</p></button>
              </div>
            </div>
          }
        />
      }


    </div>
  );
};

export default WinnerCard;
