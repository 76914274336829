import { Contract } from '@ethersproject/contracts';
import { Chain } from '@wagmi/core';
import { ethers } from "ethers";

import APCABI from '../abis/APC.json';
import APCVaultABI from '../abis/APCVault.json';
import StoreABI from '../abis/AngryPitbullClubStore.json';
import TokenABI from '../abis/Token.json';

export const custom_network = {
  id: Number(process.env.REACT_APP_CHAINID),
  name: process.env.REACT_APP_CHAINNAME,
  network: process.env.REACT_APP_CHAINNETWORK,
  nativeCurrency: {
    decimals: 18,
    name: process.env.REACT_APP_CHAIN_CURRENCY_NAME,
    symbol: process.env.REACT_APP_CHAIN_CURRENCY_SYMBOL,
  },
  rpcUrls: {
    public: { http: [process.env.REACT_APP_RPC] },
    default: { http: [process.env.REACT_APP_RPC] },
  },
  blockExplorers: {
    etherscan: { name: 'Etherscan', url: process.env.REACT_APP_EXPLORE },
    default: { name: 'Etherscan', url: process.env.REACT_APP_EXPLORE },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 6507670,
    },
  }
} as Chain


export function truncateWalletString(walletAddress: string) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(0, 7);
  const endStr = walletAddress.substring(lengthStr - 7, lengthStr);
  return startStr + '...' + endStr;
}

export function numberToString(n1: { toLocaleString: (arg0: string) => any; }) {
  if (n1) {
    const cn1 = n1.toLocaleString('en-US');
    return cn1;
  } else {
    return '';
  }
}

export function formatNum(value: number) {
  let intValue = Math.floor(value)
  if (intValue < 1) {
    return ''+ parseFloat(value.toString()).toPrecision(2);
  } else if (intValue < 1000) {
    return ''+ parseFloat(value.toString()).toFixed(1)
  } else if (intValue < 1000000) {
    return parseFloat((intValue/1000).toString()).toFixed(1) + 'K'
  } else if (intValue < 1000000000) {
    return parseFloat((intValue/1000000).toString()).toFixed(1) + 'M'
  } else if (intValue < 1000000000000){
    return parseFloat((intValue/1000000000).toString()).toFixed(1) + 'B'
  } else {
    return parseFloat((intValue/1000000000000).toString()).toFixed(1) + 'T'
  }
}

export function truncateHashString(txhash: string) {
  if (!txhash) return txhash;
  const lengthStr = txhash.length;
  const startStr = txhash.substring(0, 10);
  const endStr = txhash.substring(lengthStr - 10, lengthStr);
  return startStr + '...' + endStr;
}

export const shorter = (str: string) => (str?.length > 8 ? str.slice(0, 6) + '...' + str.slice(-4) : str);

export function getAPCVaultContract(provider: any) {
  if (!!provider) {
    return new Contract(process.env.REACT_APP_VAULT, APCVaultABI, provider);
  } else {       
    return null;
  }
}

export function getAPCContract(provider: any) {
  if (!!provider) {
    return new Contract(process.env.REACT_APP_APC, APCABI, provider);
  } else {       
    return null;
  }
}

export function getStoreContract(provider: any) {
  if (!!provider) {
    return new Contract(process.env.REACT_APP_STORE, StoreABI, provider);
  } else { 
    const rpcProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC);
    return new Contract(process.env.REACT_APP_STORE, StoreABI, rpcProvider);
  }
}

export function getTokenContract(tokenAddr:any, provider: any) {
  if (!!provider) {
    return new Contract(tokenAddr, TokenABI, provider);
  } else {       
    return null;
  }
}