import { BrowserRouter as Switch, Route } from 'react-router-dom';
import Layout from 'components/Layout';

import ScrollToTop from 'utils/scrollToTop';

import Home from 'containers/Home';
import ProfilePage from 'containers/ProfilePage/ProfilePage';
import RewardPage from 'containers/RewardPage/RewardPage';
import RosterPage from 'containers/RosterPage/RosterPage';
import InventoryPage from 'containers/InventoryPage/InventoryPage';

const Routes = () => (
  <>
    <Switch>
      <Layout>
        <ScrollToTop />
        <Route exact path="/" component={Home} />
        <Route exact path="/rewards" component={RewardPage} />
        <Route exact path="/roster" component={RosterPage} />
        <Route exact path="/inventory" component={InventoryPage} />
        <Route exact path="/profile" component={ProfilePage} />
      </Layout>
    </Switch>
  </>
);

export default Routes;
