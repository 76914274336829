import './accountModal.scss'
import Bounce  from 'react-reveal/Bounce';
import { useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";
import { truncateWalletString } from 'utils';
import FilledButton from 'components/Buttons/FilledButton';
interface Props {
    showAccountModal: boolean,
    setShowAccountModal?: any

}
const AccountModal: React.FC<Props> = ({
    showAccountModal,
    setShowAccountModal
}) => {
    const [isStart, setIsStart] = useState(false)
    useEffect(() => {
        if(showAccountModal)
        {
            setTimeout(() => {
                setIsStart(true)
            }, 100)
        }
        
    }, [setIsStart,  showAccountModal]);
    const onClose = ()=>{
        setIsStart(false)
        setTimeout(() => {
            setShowAccountModal(false);
        }, 800)
    }

    const inputEl = useRef<HTMLInputElement>(null);
    const clickHandle = () => {
        if (inputEl && inputEl.current) {
            inputEl.current.select();
            document.execCommand('copy');
            toast.success("Copied Address!");
        }
    }

    const onDisconnect = ()=>{
        setIsStart(false)
        setTimeout(() => {
            setShowAccountModal(false);
        }, 800)
    }
    return (
        <div className={showAccountModal === true ? "accountModal active" : "accountModal"}>
            <Bounce opposite when={isStart}>
            <div className="modelContent">
                <div className="connectWalletHeader">
                    <button className="connectModalCloseButton" onClick={onClose}><i className="fas fa-times"></i></button>
                    <h1 className="connectWalletTitle">Account</h1>
                </div>
                <div className="modalWraper">
                    <span className='row'>
                        <img src="assets/avatars/avatar.png" alt="" className="avatar" />
                        <input className="addressInput " 
                            ref={inputEl} 
                            type="text" 
                            value={truncateWalletString('aaaa') || ''}
                            onChange={() => { }} 
                        />
                        <button className="coppyAddressButton" onClick={clickHandle}>
                            <i className="fas fa-copy"></i>
                        </button>
                    </span>
                    <div className="modalBtns">
                        <a href="/" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> View on explorer</a>
                        <FilledButton label={'Disconnect'} color = 'grey' handleClick={onDisconnect}/>
                    </div>
                </div>
            </div>
            </Bounce>

        </div>
    )
}
export default AccountModal;