import React from 'react';
import './loader.scss';

export default function Loader(props) {
  const { isLoading, label } = props;
  return (
    <div
      className='loader-div'
      style={{
        opacity: isLoading ? 1 : 0,
        zIndex: isLoading ? 9991 : -1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '848px', // Adjust the size of the video as needed
          height: '848px',
          overflow: 'hidden',
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            width: '100%',
            height: '100%',
            mixBlendMode: 'multiply', // Using multiply blend mode to attempt transparency for black
            filter: 'brightness(1.5) contrast(1.2)', // Adjusting brightness and contrast
          }}
        >
          <source src="/assets/Angry_Pitbull_Club_Logo_Spinning.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}
