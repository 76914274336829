import './menu.scss';
import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import ConnectModal from 'components/modal/connectModal/ConnectModal';
import AccountModal from 'components/modal/accountModal/AccountModal';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

type MenuType = {
  menuOpen: boolean;
  setMenuOpen(flag: boolean): void;
};

export default function Menu({ menuOpen, setMenuOpen }: MenuType) {
  
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showAcountModal, setShowAcountModal] = useState(false);

  const [navId, setNavId] = useState("");
  const search = useLocation();
  useEffect(() => {
    
    if(search.hash.includes("#")){
      const hash = search.hash.replace("#", "");
      setNavId(hash);
    }else{
      const hash = search.pathname.replace("/", "");
      setNavId(hash);
    }
    
  }, [setNavId, search]);
  return (
    <>
    <div className={'menubar ' + (menuOpen && 'active')}>
      <div className="menuContent">
        <div className="sideBar">
        <div className="navList" >
          <ul className="menu">

            <li className={clsx("menu-item", navId === "" ? "selected" : "")} onClick={() => setMenuOpen(false)}>
              <HashLink to="/"> <img src="/assets/icons/icon_dashboard.svg" alt="" /> Dashboard</HashLink>
            </li>
            <li className={clsx(navId === "rewards" ? "selected_menu color1" : "")} onClick={() => setMenuOpen(false)}>
              <HashLink to="/rewards">{navId === "rewards" ? <img src="/assets/icons/icon_reward_01.svg" alt="" />:<img src="/assets/icons/icon_reward.svg" alt="" />} Rewards</HashLink>
            </li>
            <li className={clsx(navId === "inventory" ? "selected_menu color3" : "")} onClick={() => setMenuOpen(false)}>
              <HashLink to="/inventory"><img src="/assets/icons/icon_inventory.svg" alt="" /> Inventroy</HashLink>
            </li>
            {/* <li className={clsx(navId === "create_project" ? "selected_menu" : "")} onClick={() => setMenuOpen(false)}>
              <HashLink to="/create_project"><img src="/assets/icons/icon_flag.svg" alt="" /></HashLink>
            </li> */}

            <li className={clsx(navId === "roster" ? "selected_menu" : "")} onClick={() => setMenuOpen(false)}>
              <HashLink to="/roster"><img src="/assets/icons/icon_chart.svg" alt="" /> Roster</HashLink>
            </li>

            <li className={clsx(navId === "profile" ? "selected_menu color2" : "")} onClick={() => setMenuOpen(false)}>
              <HashLink to="/profile"><img src="/assets/icons/icon_profile.svg" alt="" /> Profile</HashLink>
            </li>

            <li className={clsx(navId === "refresh" ? "selected_menu" : "")} onClick={() => setMenuOpen(false)}>
              <HashLink to="/refresh"><img src="/assets/icons/icon_refresh.svg" alt="" /></HashLink>
            </li>

          </ul>

        </div>
      </div>
      </div>
    </div>
    <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
    <AccountModal showAccountModal={showAcountModal} setShowAccountModal={setShowAcountModal} />
    </>
  );
}
