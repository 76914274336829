import { useStyles } from './style';
import { useEffect, useState } from 'react';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import { fetchSigner } from '@wagmi/core';
import { shorter, formatNum, custom_network } from 'utils';
import { getTotalEarning, getOwnedTokenIds, getStakedTokenIds } from 'utils/contracts';
import Loader from 'components/LoadingPage/Loader';
import data from './context_data.json';
import TransactionTable from 'components/Sections/DashboardSection/TransactionTable';
import { HashLink } from 'react-router-hash-link';
import { ethers } from 'ethers';

const Home = () => {
  const classes = useStyles();

  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const [signer, setSigner] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState('/assets/icons/icon_profile.svg');

  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id)) {
      fetchSigner()
        .then((result) => {
          if (result) {
            setSigner(result);
            fetchAndSetAvatar(address)
          }
        })
        .catch((error) => {
          console.log('fetch signer error:', error);
          fetchAndSetAvatar(address)
          setSigner(null);
        })
    }
  }, [address, isConnected, chain]);


  const fetchAndSetAvatar = async (address: any) => {
    try {

      console.log("address", address)
      const provider = new ethers.providers.JsonRpcProvider('https://ethereum.blockpi.network/v1/rpc/public'); // Replace with your BlockPi RPC URL
      console.log("provider", provider)
      const tokenIds = await getOwnedTokenIds(provider, address);
      const tokenIdsStaked = await getStakedTokenIds(provider, address);
      console.log("tokenIds", tokenIds)
      console.log("tokenIdsStaked", tokenIdsStaked)

      if (tokenIds.length > 0) {
        const randomTokenId = tokenIds[Math.floor(Math.random() * tokenIds.length)];
        setAvatarUrl(`${process.env.REACT_APP_IMG_BASEURL}${randomTokenId}.png`);
      } else if (tokenIdsStaked.length > 0) {
        const randomTokenId = tokenIdsStaked[Math.floor(Math.random() * tokenIdsStaked.length)];
        setAvatarUrl(`${process.env.REACT_APP_IMG_BASEURL}${randomTokenId}.png`);
      } else {
        setAvatarUrl('/assets/icons/icon_profile.svg');
      }
    } catch (error) {
      console.error('Error fetching token IDs:', error);
      setAvatarUrl('/assets/icons/icon_profile.svg');
    }
  };

  const [totalEarning, setTotalEarning] = useState(0.0);
  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id) && signer) {
      getTotalEarning(signer, address)
        .then((result) => {
          if (result) {
            console.log('totalEarning:', result);
            setTotalEarning(result);
          }
        })
        .catch((error) => {
          console.log('totalEarning fetch error:', error);
          setTotalEarning(0)
        })
    }
  }, [address, isConnected, chain, signer]);

  const [isLoading, setIsLoading] = useState(true);
  const [imgCount, setImgCount] = useState(0)
  const onLoad = () => {
    setImgCount(imgCount + 1)
  }
  useEffect(() => {
    if (imgCount >= 8) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500);
    }
  }, [setIsLoading, imgCount]);
  const top_transactions = data?.transactions;
  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={classes.root}>
        <div className={`${classes.container}`}>
          <div className={classes.top}>
            <div className={classes.title}>
              <img src={avatarUrl} alt="" onLoad={onLoad} />
              <h1>Dashboard</h1>
            </div>
          </div>

          <div className={classes.content}>
            <div className={classes.col_left}>
              <div className={classes.state}>
                <div className="left">
                  <div className="earn">
                    <div>
                      <img src="/assets/logo.png" alt="" onLoad={onLoad} />
                      <p>Earnings</p>
                      <h2>{formatNum(totalEarning)}</h2>
                      <button onClick={() => window.open('/roster', '_self')}>Earn</button>
                    </div>
                  </div>
                </div>
                <div className="right">
                  {/* <div className="card1">
                    <div className="card_content">
                      <h3>$ 234,900</h3>
                      <p>Revenue</p>
                      <div className="circle"></div>
                    </div>
                  </div>
                  <div className="card2">
                    <div className="card_content">
                      <h3>$ 234,900</h3>
                      <p>Expences</p>
                      <div className="circle"></div>
                    </div>
                  </div> */}
                  <HashLink className="card3" to="/rewards">
                    <h3>Online Store</h3>
                    <img src="/assets/imgs/store.png" alt="" onLoad={onLoad} />
                    <p>Shop for purchasing items with tokens</p>
                  </HashLink>

                </div>
              </div>
            </div>
            <div className={classes.col_right}>
              <div className={classes.news}>
                <div className="text">
                  <h4>NEWS</h4>
                  <h3>How does Staking work?</h3>
                </div>
                <img src="/assets/imgs/news_bg.png" alt="" onLoad={onLoad} />
              </div>
            </div>
            {/* <div className={classes.col_left}>
              <div className={classes.transaction}>
                <TransactionTable data = {top_transactions}/>
              </div>
            </div> */}
            <div className={classes.col_right}>
              <div className={classes.inventory}>
                <div className="top">
                  <h3>Inventory</h3>
                  <HashLink to="/inventory" className="iconBtn">Go Collection</HashLink>
                </div>
                <div className="img_list">
                  <img src="/assets/imgs/img_01.png" alt="" className='img img_01' onLoad={onLoad} />
                  <img src="/assets/imgs/img_02.png" alt="" className='img img_02' onLoad={onLoad} />
                  <img src="/assets/imgs/img_03.png" alt="" className='img img_03' onLoad={onLoad} />
                  <img src="/assets/imgs/img_04.png" alt="" className='img_04' onLoad={onLoad} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>

  );
};

export default Home;
