import { useStyles } from './style';
import { useEffect, useState } from 'react';
import { custom_network } from 'utils';
import { useAccount, useNetwork } from 'wagmi';
import { fetchSigner } from '@wagmi/core';
import { getNFTBalance } from 'utils/contracts';

import MySelect from 'components/Forms/MySelect';
import Masonry from 'react-masonry-css';
import WinnerCard from 'components/Cards/WinnerCard/WinnerCard';
import Loader from 'components/LoadingPage/Loader';
import { getAllItemListingArray } from 'utils/contracts';

const RewardPage = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [imgCount, setImgCount] = useState(0)
  const onLoad = () => {
    setImgCount(imgCount + 1)
  }
  useEffect(() => {
    if (imgCount >= 2) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500);
    }
  }, [setIsLoading, imgCount]);

  const viewOption = [
    { label: 'All', value: 0 },
    { label: 'High Price', value: 1 },
    { label: 'Low Price', value: 2 },
  ]
  const [view, setView] = useState(viewOption[0].value);

  const rankingOption = [
    { label: 'Angry Pitbull', value: 0 },
    { label: 'Bully', value: 1 },
    { label: 'Trap Lord', value: 2 },
    { label: 'King Pit', value: 2 },
    { label: 'Cerberus', value: 2 },
    { label: 'Anubis', value: 2 },
    { label: 'Trap Lord', value: 2 },
    { label: 'Trap Lord', value: 2 },
  ]
  const [ranking, setRanking] = useState(rankingOption[0].value);
  const breakpoint = {
    default: 5,
    1840: 5,
    1440: 4,
    1280: 2,
    768: 2,
    450: 1,
  };
  const [tabID, setTabID] = useState(0);

  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const [signer, setSigner] = useState(null);
  const [nftBalance, setNftBalance] = useState(0);
  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id)) {
      fetchSigner()
        .then((result) => {
          if (result) {
            console.log('fetch signer:', result);
            setSigner(result);
          }
        })
        .catch((error) => {
          console.log('fetch signer error:', error);
          setSigner(null);
        })
    }
  }, [address, isConnected, chain]);

  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id) && signer) {
      getNFTBalance(address, signer)
        .then((result) => {
          if (result) {
            console.log('NFTBalance:', result);
            setNftBalance(result);
          }
        })
        .catch((error) => {
          console.log('NFTBalance fetch error:', error);
          setNftBalance(0)
        })
    }
  }, [address, isConnected, chain, signer]);


  const [itemListingArray, setItemListingArray] = useState([]);

  useEffect(() => {
    getAllItemListingArray(null)
      .then((result) => {
        if (result) {
          console.log('itemListingArray:', result);
          // Convert array to include indices
          const indexedArray = result.map((item, index) => ({ item, index }));
          setItemListingArray(indexedArray);
        }
      })
      .catch((error) => {
        console.log('itemListingArray fetch error:', error);
        setItemListingArray([]);
      });
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={classes.root}>
        <div className={`${classes.container}`}>
          <div className={classes.top}>
            <div className={classes.title}>
              <div className="icon">
                <img src="/assets/icons/icon_reward_01.svg" alt="" onLoad={onLoad} />
              </div>
              <h1>Rewards</h1>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.filter}>
              <div className="tab_btns">
                <button onClick={() => setTabID(0)} className={tabID === 0 ? 'activeBtn' : ''}>Angry Pitbull Club</button>
                <button onClick={() => setTabID(1)} className={tabID === 1 ? 'activeBtn' : ''}>Jailed Pits</button>
              </div>
              <div className="select_div">
                <div className="col">
                  <h4>View</h4>
                  <MySelect
                    value={view}
                    options={viewOption}
                    onChange={setView}
                    className='filter_select'
                  />
                </div>
                <div className="col">
                  <h4>Ranking</h4>
                  <MySelect
                    value={ranking}
                    options={rankingOption}
                    onChange={setRanking}
                    className='filter_select'
                  />
                </div>
              </div>
            </div>
            <div className={classes.sub_title}>
              <img src="/assets/icons/icon_circle_01.svg" alt="" onLoad={onLoad} />
              <h2>GIVEAWAYS</h2>
            </div>
            <Masonry
              breakpointCols={breakpoint}
              className={classes.masonry}
              columnClassName={classes.gridColumn}
            >
              {itemListingArray.filter(({ item }) => Number(item.bought) < Number(item.totalSupply)).map(({ item, index }) => (
                <WinnerCard
                  key={index}
                  item={item}
                  itemIndex={index}
                  chain={chain}
                  address={address}
                  isConnected={isConnected}
                  signer={signer}
                  nftBalance={nftBalance}
                />
              ))}
            </Masonry>
            {itemListingArray.some(({ item }) => Number(item.bought) >= Number(item.totalSupply)) && (
              <>
                <div className={classes.sub_title}>
                  <img src="/assets/icons/icon_circle_01.svg" alt="" onLoad={onLoad} />
                  <h2>COMPLETED</h2>
                </div>
                <Masonry
                  breakpointCols={breakpoint}
                  className={classes.masonry}
                  columnClassName={classes.gridColumn}
                >
                  {itemListingArray.filter(({ item }) => Number(item.totalSupply) > 0 && Number(item.bought) >= Number(item.totalSupply)).map(({ item, index }) => (
                    <WinnerCard
                      key={index}
                      item={item}
                      itemIndex={index}
                      chain={chain}
                      address={address}
                      isConnected={isConnected}
                      signer={signer}
                      nftBalance={nftBalance}
                    />
                  ))}
                </Masonry>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default RewardPage;
