import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 454,
    borderRadius: 40,
    marginBottom: 40,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // 
    border: '1.5px dashed #323655',
    overflow : 'hidden',
    transition : 'all 0.3s ease',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width : '100%',
      marginBottom: 16,
    },
    
    '&:hover': {
      transform : 'translateY(-10px)'
    },
    '&.selected': {
      background: 'rgba(255, 255, 255, 0.1)',
      '& button': {
        background : 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #912D3F 0%, #FF4062 100%)'
      }
    },
  },
  card_body: {
    width : '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex : 1,
    boxSizing : 'border-box',
    padding : '24px 16px',
    [theme.breakpoints.down('xs')]: {
    },
    '& button': {
      background : 'rgba(117, 132, 157, 0.1)',
      border : 'none',
      padding : '15px 20px',
      width : '100%',
      color : '#EEEEEE',
      fontSize : 12,
      borderRadius : '8px 8px 24px 24px',
      transition : 'all 0.3s ease',
      cursor : 'pointer',
      [theme.breakpoints.down('xs')]: {
        flexWrap : 'wrap',
      },
      '&:hover': {
        // filter : 'brightness(1.5)',
        background : 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #912D3F 0%, #FF4062 100%)'
      },
     
    },
  },
  nft_div: {
    width : '40%',
    display: 'flex',
    alignItems: 'center',
    marginBottom : 16,
    [theme.breakpoints.down('xs')]: {
    },
    '& .img-div' : {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      paddingBottom : '100%',
      height : 0,
      borderRadius : '50%',
      // overflow : 'hidden',
      [theme.breakpoints.down('xs')]: {
      },
  
      '& img': {
        width: '100%',
        height: '100%',
        objectFit : 'cover',
        position: 'absolute',
        top : 0,
        left : 0,
        borderRadius: '100%'
      },      
    },
  },
  state: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      
    },
    '& .row': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom : 16,
      '& p': {
        color : '#75849D',
        fontSize: 12,
        opacity : 0.8
      },
      '& h3': {
        color : '#E2EBFB',
        fontSize: 19,
        fontWeight: 600,
      },
      '& h4': {
        color : '#639DFF',
        fontSize: 12,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        '& img': {
          marginLeft : 16,
        },
      },
      '& h5': {
        color : '#E2EBFB',
        fontSize: 12,
        fontWeight: 600,
        '& span': {
          color : '#75849D',
        },
      },
    },
    '& .progress-div':{
      width: '100%',
      height : 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom : 16,
      gridArea : 'auto',
      background : '#3A3F5F',
      gap : 10,
      '& .progress':{
        height : 4,
        borderRadius : 5,
        background : 'linear-gradient(133.78deg, #4D74FF 1.75%, #33FFF3 103.5%)',
      },
    },
    
  },
}));



export default useStyles;
