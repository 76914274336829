import { useStyles } from './style';

interface PropsType {
  item?: any;
  amount?: any;
  onClick?: any;
}

const CollectionCard = ({ item, amount, onClick }: PropsType) => {
  const classes = useStyles();
  // originally item.image
  return (
    <div className={classes.root}>
      <div className={classes.card_body} onClick={onClick}>
        <div className='img-div'>
          <img src="/assets/imgs/reward_01.png" alt="" />
        </div>
        <div className={classes.state}>
          <div className="row">
            <h3>{item.header}</h3>
            <p>x{amount}</p>
          </div>
          {/* <div className="row">
            <h5>x{amount}</h5>
            <a href="https://opeansea.com" target={'_blank'} rel="noreferrer"><img src="/assets/icons/icon_opensea.svg" alt="" /></a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CollectionCard;
