import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height : '100%',
    position : 'relative',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    width: '100%',
    margin : 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize : '100%',
    backgroundRepeat : 'no-repeat',
    paddingTop : 80,
    [theme.breakpoints.down('xs')]: {
      paddingTop : 40,
    },
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom : 20,
    gridArea : 'auto',
    gap : 32,
    [theme.breakpoints.down('xs')]: {
      gap : 16,
    },
    '& img': {
      width : 48,
      height : 48,
      borderRadius : 18,
      objectFit : 'cover',
      [theme.breakpoints.down('xs')]: {
      },
    },
    '& h1': {
      color : '#E2EBFB',
      fontSize: 48,
      fontWeight : 800,
      lineHeight : 1,
      [theme.breakpoints.down('xs')]: {
        fontSize: 32,
      },
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight : 'auto',
    },
  },
  col_content: {
    width: '100%',
    position : 'relative',
    display: 'flex',
    alignItems: 'center',
    padding : '16px',
  },
  
  content: {
    width: '100%',
    display: 'flex',
    flexWrap : 'wrap',
    alignItems: 'center',
    paddingTop : 40,
    paddingBottom : 40,
    position : 'relative',
    gridArea : 'auto',
    gap : '64px 40px',
    [theme.breakpoints.down('sm')]: {
      flexDirection : 'column',
      paddingTop : 20,
      paddingBottom : 20,
    },
  },

  col_left: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  col_right: {
    width: 'calc(40% - 40px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  state: {
    width: '100%',
    position : 'relative',
    display: 'flex',
    gridAre : 'auto',
    gap : 40,
    [theme.breakpoints.down('sm')]: {
      flexDirection : 'column',
      justifyContent : 'center',
      alignItems: 'center',
    },
    '& .left': {
      maxWidth: 265,
      width : 'calc(40% - 20px)',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '& .earn': {
        width : '100%',
        height : 0,
        paddingBottom : '100%',
        position : 'relative',
        '& div': {
          width : '100%',
          height : '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent : 'space-between',
          border : '2px dashed #323655',
          borderRadius: 40,
          boxSizing : 'border-box',
          padding : 40,
          gridArea : 'auto',
          // gap : 30,
          position : 'absolute',
          top : 0,
          left : 0,
          
        },
        
        '& img': {
          width : 40,
        },
        '& button': {
          marginTop : 'auto',
          background: 'linear-gradient(108.11deg, #93E6DF -9.24%, #15433F 113.24%)',
          border  : '2px solid #39A89E',
          borderRadius : 16,
          fontSize : 12,
          padding : '10px 20px',
          color : '#E2EBFB',
          cursor : 'pointer',
          position : 'absolute',
          transition : 'all 0.3s ease',
          bottom : -20,
          '&:hover': {
            color : '#fff',
            background: 'linear-gradient(108.11deg, #abece6 -9.24%, #206b65 113.24%)',
          },
        },
        '& h2': {
          color : '#E2EBFB',
          fontSize : 'min(64px, 3.5vw)',
          fontWeight : 200,
          display: 'flex',
          alignItems: 'flex-end',
          lineHeight : 1,
          letterSpacing: '-0.04em',
          [theme.breakpoints.down('xs')]: {
            fontSize : 64,
          },
          '& span': {
            color : '#E2EBFB',
            fontSize : 'min(32px, 2.5vw)',
            opacity : 0.24,
            marginLeft : 8,
            [theme.breakpoints.down('xs')]: {
              fontSize : 32,
            },
          },
        },
        '& p': {
          color : '#75849D',
          fontSize : 12,
          marginBottom : 0,
          textAlign : 'left',
        },
      },
    },
    '& .right': {
      width: 'calc(60% - 20px)',
      maxWidth : 417,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position : 'relative',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& .card1': {
        border : 'none',
        width : '50%',
        height : '40%',
        borderRadius : 36,
        left : 0,
        top : '10%',
        position : 'absolute',
        overflow : 'hidden',
        boxShadow : '-10px 10px 30px #a93a3a88',
        [theme.breakpoints.down('xs')]: {
          borderRadius : 24,
        },
        '& .card_content':{
          display : 'flex',
          flexDirection : 'column',
          alignItems: 'center',
          justifyContent : 'center',
          background: 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #CA1DA7 0%, #EE703A 100%)',
          width : '100%',
          height : '100%',
          borderRadius : 36,
          [theme.breakpoints.down('xs')]: {
            borderRadius : 24,
          },
          '&:before': {
            content : "''",
            position : 'absolute',
            width: 286.02,
            height: 115.99,
            background: 'rgba(9, 16, 19, 0.16)',
            transform: 'rotate(-158.75deg)',
            left: -140,
            bottom: -50,
            borderRadius :'50%',
          },
          '&:after': {
            content : "''",
            position : 'absolute',
            width: 286.02,
            height: 115.99,
            background: 'rgba(9, 16, 19, 0.16)',
            transform: 'rotate(-158.75deg)',
            right: -140,
            top: -50,
            borderRadius :'50%',
          },
          '& .circle': {
            position : 'absolute',
            width: 360,
            height: 146,
            background: 'rgba(9, 16, 19, 0.16)',
            transform: 'rotate(-158.75deg)',
            left: -180,
            bottom: -65,
            borderRadius :'50%',
          },
        },
        
        '& h3': {
          color : '#E2EBFB',
          fontSize : 24,
          marginBottom : 16,
          fontWeight : 800,
          lineHeight : 1,
        },
        '& p': {
          color : '#D1DEF3',
          fontSize : 12,
          marginBottom : 0,
          textAlign : 'center',
        },
      },
      '& .card2': {
        width : '65%',
        maxWidth : 275,
        height : 0,
        paddingBottom : '38%',
        position : 'relative',
        marginRight : 0,
        marginLeft : 'auto',
        marginBottom : 50,
        boxShadow : '-5px 5px 20px #00000055, 0px 10px 30px #33FFF355',
        borderRadius : 36,
        [theme.breakpoints.down('xs')]: {
          borderRadius : 24,
        },
        '& .card_content':{
          width: '100%',
          height: '100%',
          display : 'flex',
          flexDirection : 'column',
          alignItems: 'center',
          justifyContent : 'center',
          background: 'linear-gradient(133.78deg, #4D74FF 1.75%, #33FFF3 103.5%)',
          borderRadius : 36,
          position : 'absolute',
          overflow : 'hidden',
          [theme.breakpoints.down('xs')]: {
            borderRadius : 24,
          },
          '&:before': {
            content : "''",
            position : 'absolute',
            width: 286.02,
            height: 115.99,
            background: 'rgba(9, 16, 19, 0.16)',
            transform: 'rotate(-158.75deg)',
            left: -140,
            bottom: -50,
            borderRadius :'50%',
          },
          '&:after': {
            content : "''",
            position : 'absolute',
            width: 286.02,
            height: 115.99,
            background: 'rgba(9, 16, 19, 0.16)',
            transform: 'rotate(-158.75deg)',
            right: -140,
            top: -50,
            borderRadius :'50%',
          },
        },
        
        '& .circle': {
          position : 'absolute',
          width: 360,
          height: 146,
          background: 'rgba(9, 16, 19, 0.16)',
          transform: 'rotate(-158.75deg)',
          right: -180,
          top: -65,
          borderRadius :'50%',
        },
        '& h3': {
          color : '#E2EBFB',
          fontSize : 24,
          marginBottom : 16,
          fontWeight : 800,
          lineHeight : 1,
        },
        '& p': {
          color : '#D1DEF3',
          fontSize : 12,
          marginBottom : 0,
          textAlign : 'center',
        },
      },
      '& .card3': {
        border : 'none',
        width : '100%',
        borderRadius : 36,
        background: 'linear-gradient(135deg, #276598, #27659844, #4f208044, #4f2080)',
        marginBottom : 0,
        marginTop : 'auto',
        position : 'relative',
        padding : 32,
        [theme.breakpoints.down('xs')]: {
          borderRadius : 24,
          padding : 24,
        },
        

        '& img': {
          width : 108,
          position : 'absolute',
          top : -10,
          right : 24,
          filter: 'drop-shadow(65px 58px 35px rgba(19, 20, 52, 0.04)) drop-shadow(36px 32px 29px rgba(19, 20, 52, 0.12)) drop-shadow(16px 14px 22px rgba(19, 20, 52, 0.2)) drop-shadow(4px 4px 12px rgba(19, 20, 52, 0.24)) drop-shadow(0px 0px 0px rgba(19, 20, 52, 0.24))',
          [theme.breakpoints.down('xs')]: {
            right : 16,
            width : 80,
          },
        },
        '& h3': {
          color : '#E2EBFB',
          fontSize : 19,
          marginBottom : 16,
          fontWeight : 600,
          [theme.breakpoints.down('xs')]: {
            marginBottom : 8,
          },
        },
        '& p': {
          color : '#75849D',
          fontSize : 12,
          marginBottom : 0,
          textAlign : 'left',
          [theme.breakpoints.down('xs')]: {
            zIndex : 2,
          },
        },
      },
      '& .leftBtn': {

      },
      '& .rightBtn': {

      },
    },
  },
  news: {
    width: '100%',
    maxWidth : 400,
    position : 'relative',
    display: 'flex',
    gridAre : 'auto',
    gap : 64,
    marginRight : 0,
    marginLeft : 'auto',
    borderRadius : 40,
    [theme.breakpoints.down('sm')]: {
      borderRadius : 32,
      margin : 0,
    },
    '& img': {
      width : '100%',
    },
    '& .text': {
      width : '100%',
      display: 'flex',
      position : 'absolute',
      boxSizing : 'border-box',
      padding : 40,
      bottom : 0,
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding : 16,
      },
      '& h3': {
        color : '#E2EBFB',
        fontSize : 19,
      },
      '& h4': {
        color : '#1D6CF1',
        fontSize : 12,
        marginBottom : 16,
        letterSpacing: '0.32em',
        [theme.breakpoints.down('sm')]: {
          marginBottom : 8,
        },
      },
      
    },

  },
  transaction:{
    width: '100%',
  },
  inventory: {
    width: '100%',
    maxWidth : 400,
    position : 'relative',
    display: 'flex',
    gridAre : 'auto',
    gap : 42,
    marginRight : 0,
    marginLeft : 'auto',
    background: 'radial-gradient(108.38% 203.37% at -4.75% 0%, #202441 0%, #282D52 100%)',
    boxShadow: '-155px 229px 110px rgba(33, 30, 77, 0.01), -87px 129px 93px rgba(33, 30, 77, 0.04), -39px 57px 69px rgba(33, 30, 77, 0.06), -10px 14px 38px rgba(33, 30, 77, 0.07), 0px 0px 0px rgba(33, 30, 77, 0.07)',
    borderRadius: 40,
    padding : 32,
    boxSizing : 'border-box',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      gap : 24,
      padding : 24,
      margin : 0,
    },
    '& .top': {
      width : '100%',
      display: 'flex',
      alignItems : 'center',
      justifyContent : 'space-between',
      
      '& h3': {
        color : '#E2EBFB',
        fontSize : 19,
      },
      '& a': {
        background: 'linear-gradient(152.59deg, #6CA3FF 7.11%, #1D6CF1 101.12%)',
        color : '#E2EBFB',
        fontSize : 12,
        padding : '10px 20px',
        borderRadius : 20,
        transition : 'all 0.3s ease',
        '&:hover':{
          background: 'linear-gradient(152.59deg, #6CA3FF 7.11%, #6CA3FF 101.12%)',
        }
      },
    },
    '& .img_list': {
      display: 'flex',
      alignItems: 'center',
      gridArea : 'auto',
      gap : 16,
      position : 'relative',
      [theme.breakpoints.down('xs')]: {
        flexWrap : 'wrap',
        width : '100%',
      },
      '& .img': {
        maxWidth : 93,
        width : '30%',
        boxShadow : '-5px 5px 20px #00000055, -10px 10px 20px #33FFF322',
        borderRadius : 24,
        [theme.breakpoints.down('xs')]: {
          width : 'calc(50% - 8px)',
          margin : '0 !important',
          maxWidth : 'calc(50% - 8px)',
        },
      },
      '& .img_02': {
        marginBottom : 42,
        
      },
      '& .img_04': {
        maxWidth : 132,
        width : '40%',
        position : 'absolute',
        left : 'calc(100% + 10px)',
        borderRadius : 24,
        boxShadow : '-5px 5px 20px #00000055, -10px 10px 20px #a93a3a33',
        [theme.breakpoints.down('xs')]: {
          width : 'calc(50% - 8px)',
          margin : '0 !important',
          position : 'relative',
          left : 0,
        },
      },
    },
    '& .state': {
      width : '100%',
      display: 'flex',
      alignItems : 'center',
      justifyContent : 'space-between',
      position : 'absolute',
      background: 'rgba(48, 55, 104, 0.16)',
      backdropFilter: 'blur(24px)',
      borderRadius: 40,
      boxSizing : 'border-box',
      padding : 32,
      top : 'calc(100% + 16px)',
      right : 0,
      [theme.breakpoints.down('sm')]: {
        position : 'relative',
        top : 'auto',
        padding : 24,
        borderRadius: 20,
        background: '#ffffff11',
      },

      
      '& ul': {
        display: 'flex',
        alignItems : 'center',
        justifyContent : 'space-between',
        listStyle : 'none',
        padding : 0,
        width : '100%',
        '& li': {
          display: 'flex',
          alignItems : 'center',
          justifyContent : 'center',
          flexDirection : 'column',
          '& h2': {
            color : '#E2EBFB',
            fontSize : 40,
            fontWeight : 200,
            '& span': {
              color : '#E2EBFB88',
              fontSize : 20,
            },
            [theme.breakpoints.down('sm')]: {
              fontSize : 24,
            },
          },
          '& p': {
            color : '#75849D',
            fontSize : 12,
            [theme.breakpoints.down('sm')]: {
              fontSize : 10,
            },
          },
        },
        
      },
    },
  },
}));



export default useStyles;
