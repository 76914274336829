/* eslint-disable no-redeclare */
import { ethers, BigNumber } from "ethers";
import { getAPCVaultContract, getAPCContract, getStoreContract, getTokenContract } from ".";
import { multicall } from '@wagmi/core';
import APCVaultABI from '../abis/APCVault.json';
import StoreABI from '../abis/AngryPitbullClubStore.json';

export function isAddress(address: any) {
    try {
        ethers.utils.getAddress(address);
    } catch (e) { return false; }
    return true;
}

export async function resolveName(name: any) {
    try {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC);
        var address = await provider.resolveName(name);
        return address;
    } catch (e) { return ''; }
}

export function toEth(amount: any, decimal: any) {
    return ethers.utils.formatUnits(String(amount), decimal);
}

export function toWei(amount: any, decimal: any) {
    return ethers.utils.parseUnits(String(amount), decimal);
}

export async function isTokenApproved(account: any, tokenAddr: any, amount: any, toAddr: any, provider: any) {
    const tokenContract = getTokenContract(tokenAddr, provider);
    if (!tokenContract) return false;

    const decimal = await tokenContract.decimals();
    const allowance = await tokenContract.allowance(account, toAddr);
    if (BigNumber.from(toWei(amount, decimal)).gt(allowance)) {
        return false;
    }
    return true;
}


export async function approveToken(tokenAddr: any, toAddr: any, provider: any) {
    const tokenContract = getTokenContract(tokenAddr, provider);
    if (!tokenContract) return false;

    const approveAmount = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
    try {
        const approve_tx = await tokenContract.approve(toAddr, approveAmount);
        await approve_tx.wait(1);
        return true;
    } catch (e) {
        console.log(e)
        return false;
    }
}

export async function balanceOfToken(tokenAddr: any, toAddr: any, provider: any) {
    const tokenContract = getTokenContract(tokenAddr, provider);
    if (!tokenContract) return false;

    try {
        const approve_tx = await tokenContract.balanceOf(toAddr);
        const res = await approve_tx.wait(1);
        return res;
    } catch (e) {
        console.log(e)
        return false;
    }
}


export async function isNFTApproved(account: any, to: any, provider: any) {
    const nftToken = getAPCContract(provider);
    if (!nftToken) return false;
    return await nftToken.isApprovedForAll(account, to);
}

export async function setNFTApproval(provider: any, to: any) {
    const nftToken = getAPCContract(provider);

    if (!nftToken) return false;
    try {
        const tx = await nftToken.setApprovalForAll(to, true);
        await tx.wait(1);
        return true;
    } catch (e) {
        console.log(e)
    }
    return false;
}

export async function getNFTBalance(account: any, provider: any) {
    const nftToken = getAPCContract(provider);
    if (!nftToken) return false;
    const balance = await nftToken.balanceOf(account);
    return Number(balance);
}



/**
 * STAKING FRUNCTIONS
 */


/**
 * get functions
 */

export async function getClaimAllAmount(provider: any, address: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return null;
    try {
        const claimAllAmount = await contract.claimAllAmount(address);
        return Number(claimAllAmount);
    } catch (e) {
        console.log(e)
        return 0;
    }
}

export async function getClaimAllTimeStamp(provider: any, address: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return null;
    try {
        const claimAllTimeStamp = await contract.claimAllTimeStamp(address);
        return Number(claimAllTimeStamp);
    } catch (e) {
        console.log(e)
        return 0;
    }
}

export async function getOwnedTokenIds(provider: any, address: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return null;
    try {
        const tokenArray = await contract.tokensOfOwnerUnstaked(address);
        const result = tokenArray.map((value: any) => {
            return Number(value)
        });
        return result;
    } catch (e) {
        console.log(e)
        return [];
    }
}

export async function getStakedTokenIds(provider: any, address: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return null;
    try {
        const tokenArray = await contract.tokensOfOwner(address);
        const result = tokenArray.map((value: any) => {
            return Number(value)
        });
        return result;
    } catch (e) {
        console.log(e)
        return [];
    }
}

export async function getStakeInfo(provider: any, tokenId: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return null;
    try {
        const tokenInfo = await contract.vault(tokenId);
        return tokenInfo;
    } catch (e) {
        console.log(e)
        return null;
    }
}

export async function getScore(provider: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return null;
    try {
        const score = await contract.score();
        return parseFloat(ethers.utils.formatUnits(score, 18));
    } catch (e) {
        console.log(e)
        return null;
    }
}

export async function getTotalEarning(provider: any, address: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return null;
    try {
        const stakedNfts = await getStakedInformations(provider, address);
        const localScore = await getScore(provider);

        const claimAllTimeStamp = await getClaimAllTimeStamp(provider, address);


        if (stakedNfts && stakedNfts.length > 0) {
            let earned = 0;
            const currentTimestamp = new Date().getTime();
            for (let index = 0; index < stakedNfts.length; index++) {
                const stakedNft = stakedNfts[index] as any;
                if (stakedNft.claimAllEnabled) {
                    earned +=
                        (localScore * (Math.floor(currentTimestamp / 1000) - claimAllTimeStamp)) / 86400.0;
                }
                earned +=
                    (localScore * (Math.floor(currentTimestamp / 1000) - Number(stakedNft.lastClaimed))) / 86400.0;
            }
            return earned;
        }
        return 0;
    } catch (e) {
        console.log(e)
        return 0;
    }
}

export async function getStakedInformations(provider: any, address: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return null;
    try {
        const tokenIds = await contract.tokensOfOwner(address);
        if (tokenIds && tokenIds.length > 0) {
            var contracts = [];
            for (let index = 0; index < tokenIds.length; index++) {
                const tokenId = Number(tokenIds[index]);
                contracts.push({
                    address: process.env.REACT_APP_VAULT,
                    abi: APCVaultABI,
                    functionName: 'vault',
                    args: [tokenId],
                })
            }
            const data = await multicall({
                contracts: contracts,
            });
            return data;
        } else {
            return [];
        }
    } catch (e) {
        console.log(e)
        return [];
    }
}

/**
 * write functions
*/
export async function stake(account: any, tokenIds: any, provider: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return false;
    try {
        let isApproved = await isNFTApproved(account, process.env.REACT_APP_VAULT, provider);
        if (!isApproved) {
            isApproved = await setNFTApproval(provider, process.env.REACT_APP_VAULT);
        }
        if (isApproved) {
            const tx = await contract.stake(tokenIds, true);
            const receipt = await tx.wait(2);
            if (receipt.confirmations) {
                return true;
            }
        }
        return false;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function unstake(tokenIds: any, provider: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return false;
    try {
        const tx = await contract.unstake(tokenIds);
        const receipt = await tx.wait(2);
        if (receipt.confirmations) {
            return true;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function claim(tokenIds: any, provider: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return false;
    try {
        const tx = await contract.claim(tokenIds);
        const receipt = await tx.wait(2);
        if (receipt.confirmations) {
            return true;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function claimAll(provider: any) {
    const contract = getAPCVaultContract(provider);
    if (!contract) return false;
    try {
        const tx = await contract.claimAll();
        const receipt = await tx.wait(2);
        if (receipt.confirmations) {
            return true;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}





/**
 * MARKETPLACE FRUNCTIONS
 */


/**
 * get functions
 */

export async function getItemListingCount(provider: any) {
    const contract = getStoreContract(provider);
    if (!contract) return null;
    try {
        const claimAllAmount = await contract.getItemListingCount();
        return Number(claimAllAmount);
    } catch (e) {
        console.log(e)
        return 0;
    }
}

export async function getItemListingInfo(provider: any, listingIndex: any) {
    const contract = getStoreContract(provider);
    if (!contract) return null;
    try {
        const itemListingInfo = await contract.vendor(listingIndex);
        return itemListingInfo;
    } catch (e) {
        console.log(e)
        return 0;
    }
}

export async function getAllItemListingArray(provider: any) {
    const contract = getStoreContract(provider);
    if (!contract) return null;
    try {
        const claimAllAmount = await contract.getItemListingCount();
        if (claimAllAmount && Number(claimAllAmount) > 0) {
            var contracts = [];
            for (let index = 0; index < Number(claimAllAmount); index++) {
                contracts.push({
                    address: process.env.REACT_APP_STORE,
                    abi: StoreABI,
                    functionName: 'vendor',
                    args: [index],
                })
            }
            const data = await multicall({
                contracts: contracts,
            });
            return data;
        } else {
            return [];
        }
    } catch (e) {
        console.log(e)
        return [];
    }
}

export async function getBoughtAmountOfItem(provider: any, address: any, index: any) {
    const contract = getStoreContract(provider);
    if (!contract) return null;
    try {
        const result = await contract.boughtAmountOfItem(address, index);
        return Number(result);
    } catch (e) {
        console.log(e)
        return 0;
    }
}

export async function getItemsBoughtByUser(provider: any, address: any) {
    const contract = getStoreContract(provider);
    if (!contract) return null;
    try {
        const result = await contract.getItemsBoughtByUser(address);
        return {
            indexes: result[0],
            amounts: result[1]
        };
    } catch (e) {
        console.log(e)
        return {
            indexes: [],
            amounts: []
        };
    }
}

/**
 * write functions
*/
export async function buyItem(account: any, tokenAddress: any, id: any, amount: any, price: any, provider: any) {
    const contract = getStoreContract(provider);
    console.log("contract", contract)
    if (!contract) return false;
    try {
        if (tokenAddress === '0x0000000000000000000000000000000000000000') {
            const tx = await contract.buyItem(tokenAddress, id, amount, {
                value: toWei(price * amount, 18)
            });
            const receipt = await tx.wait(2);
            if (receipt.confirmations) {
                return true;
            }
            return false;
        } else {
            const Token = getTokenContract("0x884aFB9b2AD35e880E52b652db75cfE54287C289", provider);
            console.log("Token", Token)
            if (!Token) return false;
            let tokenApproveStatus = await isTokenApproved(account, tokenAddress, price * amount, process.env.REACT_APP_STORE, provider);
            if (!tokenApproveStatus) {
                tokenApproveStatus = await approveToken(tokenAddress, process.env.REACT_APP_STORE, provider);
            }

            console.log("tokenApproveStatus", tokenApproveStatus)
            if (tokenApproveStatus) {
                const tx = await contract.buyItem(tokenAddress, id, amount, {
                    value: BigNumber.from(0)
                })

                console.log("tx", tx)
                const res = await tx.wait(2);
                return true;
            }
            return false;
        }

    } catch (e) {
        console.log(e);
        return false;
    }
}

