import { useStyles } from './style';
import { useEffect, useState } from 'react';

import { custom_network } from 'utils';
import { useAccount, useNetwork } from 'wagmi'

import Masonry from 'react-masonry-css';
import NFTCard from 'components/Cards/NFTCard/NFTCard';
import CheckBox from 'components/Forms/CheckBox';
import Modal from 'components/modal';
import Loader from 'components/LoadingPage/Loader';
import { toast } from 'react-toastify';
import StakingCard from 'components/Cards/StakingCard/StakingCard';
import { fetchSigner } from '@wagmi/core';

import { getClaimAllAmount, getOwnedTokenIds, getStakedInformations, stake, unstake, claim, claimAll } from 'utils/contracts';

const RosterPage = () => {
  const classes = useStyles();

  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();

  const [signer, setSigner] = useState(null);
  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id)) {
      fetchSigner()
        .then((result) => {
          if (result) {
            setSigner(result);
          }
        })
        .catch((error) => {
          console.log('fetch signer error:', error);
          setSigner(null);
        })
    }
  }, [address, isConnected, chain]);

  const [isLoading, setIsLoading] = useState(true);
  const [imgCount, setImgCount] = useState(0)
  const onLoad = () => {
    setImgCount(imgCount + 1)
  }
  useEffect(() => {
    if (imgCount >= 2) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500);
    }
  }, [setIsLoading, imgCount]);

  const viewOption = [
    { label: 'All', value: 0 },
    { label: 'High Price', value: 1 },
    { label: 'Low Price', value: 2 },
  ]
  const [view, setView] = useState(viewOption[0].value);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const breakpoint = {
    default: 4,
    1840: 4,
    1680: 3,
    1280: 2,
    768: 2,
    450: 1,
  };

  //---------------------
  const [showModal, setShowModal] = useState(false);

  const [stakedNfts, setStakedNfts] = useState([]);
  const [claimAllAmount, setClaimAllAmount] = useState(0);

  const [selectedTokenIds, setSelectedNftIds] = useState<number[]>([]);

  const [selectedNFT, setSelectedNFT] = useState<any>();

  function getlevel(timestamp: any) {
    const currentTimestamp = new Date().getTime();
    const distanceTimeStamp = Math.floor(currentTimestamp / 1000) - Number(timestamp);
    const distanceDate = Math.floor(distanceTimeStamp / 86400);
    var level = '';
    if (distanceDate >= 365) {
      level = 'Multiversal Voyager';
    } else if (distanceDate >= 270) {
      level = 'Seasoned Explorer';
    } else if (distanceDate >= 180) {
      level = 'Navigator';
    } else if (distanceDate >= 90) {
      level = 'Expeditionist';
    } else if (distanceDate >= 30) {
      level = 'Adventurer';
    } else if (distanceDate >= 1) {
      level = 'Wanderer';
    }
    return level;
  }

  const handleSelect = (tokenID: any) => {
    setSelectedNftIds(selectedTokenIds.concat([tokenID]))
  }
  const handleSelectAll = () => {
    const allIDs = stakedNfts.map((stakedNft, k) => stakedNft?.tokenId)
    setSelectedNftIds(allIDs)
  }
  const handleDeselect = (tokenID: any) => {
    setSelectedNftIds(selectedTokenIds.filter((nftId) => nftId !== tokenID))
  }
  const handleDeselectAll = () => {
    setSelectedNftIds([])
    setIsCheckAll(false)
  }
  const onClickNFT = (isSelected: boolean, stakedNft: any) => {
    isSelected ? handleDeselect(stakedNft.tokenId) : handleSelect(stakedNft.tokenId)
  }
  useEffect(() => {
    if (isCheckAll) {
      handleSelectAll()
    }
    else {
      setSelectedNftIds([])
    }
  }, [isCheckAll]);


  const onClaimAll = () => {
    if (claimAllAmount === 0) {
      toast.error('ClaimAll is not available now!');
      return;
    }
    const load_toast_id = toast.loading("Claiming All...");
    claimAll(
      signer
    ).then(async (result) => {
      toast.dismiss(load_toast_id);
      if (result) {
        toast.success("Claiming All Success!");
        return;
      } else {
        toast.error("Failed to Claim All");
        return;
      }
    }).catch(err => {
      console.log('error:', err);
      toast.dismiss(load_toast_id);
      toast.error("Failed Transaction");
      return;
    });
  }
  const onClaim = () => {
    if (selectedTokenIds.length === 0) {
      toast.error('Please select NFTs!');
      return;
    }

    const load_toast_id = toast.loading("Claiming...");
    claim(
      selectedTokenIds,
      signer
    ).then(async (result) => {
      toast.dismiss(load_toast_id);
      if (result) {
        toast.success("Claim Success!");
        window.location.reload();
        return;
      } else {
        toast.error("Failed to Claim");
        return;
      }
    }).catch(err => {
      console.log('error:', err);
      toast.dismiss(load_toast_id);
      toast.error("Failed Transaction");
      return;
    });

  }
  const onUnstake = () => {
    if (selectedTokenIds.length === 0) {
      toast.error('Please select NFTs!');
      return;
    }

    const load_toast_id = toast.loading("Unstaking...");
    unstake(
      selectedTokenIds,
      signer
    ).then(async (result) => {
      toast.dismiss(load_toast_id);
      if (result) {
        toast.success("Unstaking Success!");
        window.location.reload();
        return;
      } else {
        toast.error("Failed to Unstaking");
        return;
      }
    }).catch(err => {
      console.log('error:', err);
      toast.dismiss(load_toast_id);
      toast.error("Failed Transaction");
      return;
    });
  }



  const [showStakeModal, setShowStakeModal] = useState(false);
  const [holdingNftIds, setHoldingNftIds] = useState([]);

  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id) && signer) {
      getOwnedTokenIds(signer, address)
        .then((result) => {
          if (result) {
            setHoldingNftIds(result);
          }
        })
        .catch((error) => {
          console.log('owned tokenIds fetch error:', error);
          setHoldingNftIds([])
        });
      getStakedInformations(signer, address)
        .then((result) => {
          if (result) {
            console.log('stakedNfts:', result);
            setStakedNfts(result);
          }
        })
        .catch((error) => {
          console.log('staked informations fetch error:', error);
          setStakedNfts([])
        })
      getClaimAllAmount(signer, address)
        .then((result) => {
          if (result) {
            console.log('ClaimAllAmount:', result);
            setClaimAllAmount(result);
          }
        })
        .catch((error) => {
          console.log('claim all amount fetch error:', error);
          setClaimAllAmount(0)
        })
    }
  }, [address, isConnected, chain, signer]);

  const [selectedHoldingNftIds, setSelectedHoldingNftIds] = useState([]);

  const handleSelectHolding = (tokenId: any) => {
    setSelectedHoldingNftIds(selectedHoldingNftIds.concat([tokenId]))
  }

  const handleDeselectHolding = (tokenId: any) => {
    setSelectedHoldingNftIds(selectedHoldingNftIds.filter((nftId) => nftId !== tokenId))
  }

  const onClickNFTHolding = (isSelected: boolean, tokenId: any) => {
    isSelected ? handleDeselectHolding(tokenId) : handleSelectHolding(tokenId)
  }
  const onShowStakeModal = () => {
    setShowStakeModal(true);
  }
  const onStake = () => {
    if (selectedHoldingNftIds.length === 0) {
      toast.error('Please select NFTs!');
      return;
    }
    const load_toast_id = toast.loading("Staking...");
    stake(
      address,
      selectedHoldingNftIds,
      signer
    ).then(async (result) => {
      toast.dismiss(load_toast_id);
      if (result) {
        toast.success("Staking Success!");
        window.location.reload();
        return;
      } else {
        toast.error("Failed to Staking");
        return;
      }
    }).catch(err => {
      console.log('error:', err);
      toast.dismiss(load_toast_id);
      toast.error("Failed Transaction");
      return;
    });

  }
  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={classes.root}>
        <div className={`${classes.container}`}>
          <div className={classes.top}>
            <div className={classes.titlecontainer}>
              <div className={classes.title}>
                <div className="icon">
                  <img src="/assets/icons/icon_chart.svg" alt="" onLoad={onLoad} />
                </div>
                <h1>The Dog Pound</h1>
              </div>
              <h4>The Angry Pitbull Club journey begins now!</h4>
            </div>
            {
              isConnected && chain && (chain.id === custom_network.id) && address &&
              <button onClick={onShowStakeModal}>
                <p>Stake</p>
              </button>
            }
          </div>
          {
            isConnected && chain && (chain.id === custom_network.id) && address &&
            <div className={classes.content}>
              <div className={classes.filter}>
                <div className="tab_btns">
                  <button onClick={onClaimAll} ><p>Claim all of the List</p></button>
                  <button onClick={onClaim} ><p>Claim</p></button>
                  <button onClick={onUnstake} ><p>Unstake</p></button>
                </div>
                {/* <div className="select_div">
                  <div className="col">
                    <h4>View</h4>
                    <MySelect
                      value={view}
                      options={viewOption}
                      onChange={setView}
                      className='filter_select'
                    />
                  </div>
                </div> */}

              </div>
              <div className={classes.select_btns}>
                <CheckBox label='Choose All' onChange={setIsCheckAll} value={isCheckAll} />
                <button onClick={() => handleDeselectAll()}><img src="/assets/icons/icon_unselect.svg" alt="" onLoad={onLoad} /> Unselect all</button>
              </div>
              {
                stakedNfts && stakedNfts.length > 0 &&
                <Masonry
                  breakpointCols={breakpoint}
                  className={classes.masonry}
                  columnClassName={classes.gridColumn}
                >
                  {stakedNfts.map((stakedNft, k) => {
                    const isSelected = selectedTokenIds.indexOf(stakedNft.tokenId) > -1;
                    return (
                      <NFTCard
                        item={stakedNft}
                        key={k}
                        onClick={() => onClickNFT(isSelected, stakedNft)}
                        showExpeditions={() => {
                          setSelectedNFT(stakedNft);
                          setShowModal(true);
                        }}
                        isSelected={isSelected}
                      />
                    )
                  }
                  )}
                </Masonry>
              }
            </div>
          }
        </div>
      </div>
      {
        selectedNFT &&
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          contentClass={classes.modalContent}
          contentClassName={classes.modalContent}
          maxWidth='md'
          children={
            <div className={classes.myModal}>
              <div className="top">
                <div className="avatar">
                  <div className="img_div">
                    <img src={`${process.env.REACT_APP_IMG_BASEURL}${selectedNFT.tokenId}.png`} alt="" onLoad={onLoad} />
                  </div>
                  <span>
                    <p>#{selectedNFT.tokenId}</p>
                    <h3>Angry Pitbull</h3>
                  </span>
                </div>
              </div>
              <div className="content">
                <div className="row">
                  <div className="line"></div>
                  <p>An Imaginary Ones' grand adventure begins right here! As your Imaginary Ones go on quests with you and complete them, they will become more experienced travelers and explorers in their own right.</p>
                </div>
                <ul>
                  <li className="day_1">
                    <div className="circle">
                      {
                        getlevel(selectedNFT.lastClaimed) === 'Wanderer' &&
                        <img src='/assets/icons/verified.svg' alt="" />
                      }
                    </div>
                    <h4>Day 1</h4>
                    <p>Wanderer</p>
                  </li>
                  <li className="day_2">
                    <div className="circle">
                      {
                        getlevel(selectedNFT.lastClaimed) === 'Adventurer' &&
                        <img src='/assets/icons/verified.svg' alt="" />
                      }
                    </div>
                    <h4>Day 30</h4>
                    <p>Adventurer</p>
                  </li>
                  <li className="day_3">
                    <div className="circle">
                      {
                        getlevel(selectedNFT.lastClaimed) === 'Expeditionist' &&
                        <img src='/assets/icons/verified.svg' alt="" />
                      }
                    </div>
                    <h4>Day 90</h4>
                    <p>Expeditionist</p>
                  </li>
                  <li className="day_4">
                    <div className="circle">
                      {
                        getlevel(selectedNFT.lastClaimed) === 'Navigator' &&
                        <img src='/assets/icons/verified.svg' alt="" />
                      }
                    </div>
                    <h4>Day 180</h4>
                    <p>Navigator</p>
                  </li>
                  <li className="day_5">
                    <div className="circle">
                      {
                        getlevel(selectedNFT.lastClaimed) === 'Seasoned Explorer' &&
                        <img src='/assets/icons/verified.svg' alt="" />
                      }
                    </div>
                    <h4>Day 270</h4>
                    <p>Seasoned Explorer</p>
                  </li>
                  <li className="day_6">
                    <div className="circle">
                      {
                        getlevel(selectedNFT.lastClaimed) === 'Multiversal Voyager' &&
                        <img src='/assets/icons/verified.svg' alt="" />
                      }
                    </div>
                    <h4>Day 365</h4>
                    <p>Multiversal Voyager</p>
                  </li>
                </ul>
              </div>

            </div>
          }
        />
      }


      <Modal
        show={showStakeModal}
        onHide={() => setShowStakeModal(false)}
        contentClass={classes.modalContent}
        contentClassName={classes.modalContent}
        maxWidth='md'
        children={
          <div className={classes.myModal}>
            <div className="top">
              <div className="title">
                <h3>Owned NFTs</h3>
              </div>
              <button onClick={() => setShowStakeModal(false)}><i className="fas fa-times"></i></button>
            </div>
            <div className="content">
              {
                holdingNftIds && holdingNftIds.length > 0 &&
                <Masonry
                  breakpointCols={breakpoint}
                  className={classes.modal_masonry}
                  columnClassName={classes.gridColumn}
                >
                  {holdingNftIds.map((tokenID, index) => {
                    const isSelected = selectedHoldingNftIds.indexOf(tokenID) > -1;
                    return (
                      <StakingCard
                        tokenID={tokenID}
                        key={index}
                        onClick={() => onClickNFTHolding(isSelected, tokenID)}
                        isSelected={isSelected}
                      />
                    )
                  }
                  )}
                </Masonry>
              }

            </div>
            <div className="bottom">
              <button onClick={onStake}><p>Stake</p></button>
            </div>
          </div>
        }
      />
    </>

  );
};

export default RosterPage;
