import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 454,
    marginBottom: 40,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // overflow : 'hidden',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width : '100%',
      marginBottom: 32,
    },
  },
  card_body: {
    cursor: 'pointer',
    width : '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // zIndex : 1,
    gridArea : 'auto',
    gap : 16,
    [theme.breakpoints.down('sm')]: {
      gap : 16,
    },
  },
  img_div : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    paddingBottom : '68%',
    height : 0,
    
    [theme.breakpoints.down('xs')]: {
    },

    '& img': {
      width: '100%',
      height: '100%',
      objectFit : 'cover',
      position: 'absolute',
      top : 0,
      left : 0,
      borderRadius: 32,
    
    },
    '& .effect_img': {
      filter: 'blur(32px)',
      opacity: 0.7,
      borderRadius: 32,
    },
  },
  row: {
    width : '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
    },
    '&.between': {
      justifyContent : 'space-between',
    },
    '& h2': {
      fontSize : 19,
      fontWeight : 600,
      color : '#E2EBFB',
      overflow: 'hidden',
       minHeight: 58,
      WebkitLineClamp: 2,
      display: 'box',
      WebkitBoxOrient: 'vertical',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
    },
    '& h4': {
      fontSize : 12,
      fontWeight : 600,
      paddingLeft : 16,
      color : '#75849D',
    },
    '& h5': {
      fontSize : 12,
      fontWeight : 600,
      color : '#E2EBFB',
    },
    '& .progress_div': {
      width : '100%',
      height : 4,
      borderRadius : 4,
      background : '#292D47',
      display: 'flex',
      overflow : 'hidden',
      '& .progress': {
        height : 4,
        borderRadius : 4,
        background : 'linear-gradient(113.15deg, #4D74FF -0.56%, #33FFF3 43.15%, #BE33FF 87.78%)',
        display: 'flex',
      },
    },
    '& .timer': {
      color : '#75849D',
      fontSize : 12,
      fontWeight : 600,
      paddingLeft : 8,
    },
    '& .circle': {
      background : '#FF6A73',
      width : 4,
      height : 4,
      borderRadius : 4,
      marginRight : 16,
    },
    '& button': {
      background : '#ffffff00',
      width : '100%',
      padding : '10px 20px',
      borderRadius : 16,
      border : '1px #75849D44 solid',
      position : 'relative',
      overflow : 'hidden',
      cursor : 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent : 'center',
      '&:disabled': {
        background : '#ffffff00',
        color : '#75849D',
        cursor : 'not-allowed'
      },
      '&:hover': {
        '&:before': {
          opacity : 1,
        },
      },
      '&:before': {
        content : "''",
        position : 'absolute',
        width : '100%',
        height : '100%',
        top : 0,
        left : 0,
        transition : 'all 0.3s ease',
        background : 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #CA1DA7 0%, #EE703A 100%)',
        color : '#75849D',
        opacity : 0.1,
      },

      '& p': {
        display: 'flex',
        alignItems: 'center',
        zIndex : '1 !important',
        color : '#EEEEEE',
        textAlign : 'center',
        gridArea : 'auto',
        gap : 8,
      },
    },
  },
  desc: {
    color: '#75849Daa',
    overflow: 'hidden',
    // maxHeight: 100,
    WebkitLineClamp: 3,
    display: 'box',
    fontSize : 14,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  modalContent : {
    padding : '56px !important',
    overflow : 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding : '10px !important',
    },
    '& .MuiPaper-root' : {
      borderRadius : 56,
      background: 'radial-gradient(115.62% 190.53% at 11.12% -2.52%, #2E3560 0%, #181C32 100%)',
      [theme.breakpoints.down('xs')]: {
        borderRadius : 20,
      },
    }
  },
  myModal : {
    width: 720,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width : '70vw',
    },
    '& .top': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom : 24,
      position : 'relative',
      width : '100%',
      '& .avatar': {
        display: 'flex',
        alignItems: 'center',
        gridArea : 'auto',
        gap : 16,
        '& .img_div': {
          width : 72,
          height : 72,
          borderRadius : 72,
          display: 'flex',
          '& img' : {
            width : '100%',
            height : '100%',
          },
        },
        '& h3': {
          color : '#E2EBFB',
          fontSize: 24,
          fontWeight : 600,
        },
        '& p': {
          color : '#75849D',
          fontSize: 13,
          fontWeight : 600,
          opacity : 0.4,
        },
      },
      '& .title': {
        display: 'flex',
        alignItems: 'center',
        gridArea : 'auto',
        gap : 16,
        '& h3': {
          color : '#E2EBFB',
          fontSize: 24,
          fontWeight : 600,
        },
      },
      '& button': {
        color : '#E2EBFB',
        background : '#ffffff00',
        border : 'none',
        fontSize : 24,
        transition : 'all 0.3s ease',
        cursor : 'pointer',
        '&:hover': {
          color : '#fff',
        },
      },
    },
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      width : '100%',
      maxHeight : '60vh',
      overflowY : 'scroll',
      [theme.breakpoints.down('sm')]: {
        maxHeight : '65vh',
      },  
      '& span': {
        color : '#51bd90',
        fontWeight : 600,
      },
      '& p': {
        color : '#c3c2c2',
        marginTop : '10px',
      }, 
      '& .inputContainer': {
        marginTop : '10px',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems : 'center',
        '& p': {
          marginTop : '0px',
        },
        '& input': {
          width: '100%',
          border: '1px solid rgb(150, 150, 150)',
          background: 'none',
          color: '#fff',
          margin: '0px 10px',
          height: '35px',
          paddingLeft: '10px',
          borderRadius: '4px',
          '&:focus':{
            outline : 'none',
          }
        },    
      },   
    },
    '& .bottom': {
      display: 'flex',
      alignItems: 'center',
      padding : 10,
      width : '100%',
      '& button':{
        fontSize : 12,
        fontWeight : 600,
        color : '#75849D',
        padding : '10px 24px',
        transition : 'all 0.3s ease',
        background : '#ffffff00',
        cursor : 'pointer',
        border : 'none',
        borderRadius : 30,
        position : 'relative',
        overflow : 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width : '100%',
        [theme.breakpoints.down('xs')]: {
        },
        '&:hover':{
          '&:before':{
            opacity : 0.7,
          },
          '& p':{
            color : '#fff',
          }
        },
        '&:before':{
          content : "''",
          position : 'absolute',
          width : '100%',
          height : '100%',
          top : 0,
          left : '0',
          transition : 'all 0.3s ease',
          background : 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #912D3F 0%, #FF4062 100%)'
        },
        '& p':{
          color : '#E2EBFB',
          zIndex : 1,
          fontSize : 16,
          transition : 'all 0.3s ease',
          [theme.breakpoints.down('xs')]: {
            fontSize : 12,
          },
        },
      },
    }
  }
}));



export default useStyles;
