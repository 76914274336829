import { useEffect, useState } from 'react';
import useStyles from './style';
import { HashLink } from 'react-router-hash-link';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

const SideBar = () => {
  const classes = useStyles();
  const [navId, setNavId] = useState("");
  const search = useLocation();
  useEffect(() => {
    
    if(search.hash.includes("#")){
      const hash = search.hash.replace("#", "");
      setNavId(hash);
    }else{
      const hash = search.pathname.replace("/", "");
      setNavId(hash);
    }
    
  }, [setNavId, search]);
  return (
    <div className={classes.root}>
        <div className={classes.logo} >
          <div className="img_div">
            <HashLink to="/"><img src="/assets/logo.png" alt="" /></HashLink>
          </div>
        </div>
        <div className={classes.navList} >
          <ul>
              <li className={clsx(navId === "" ? "selected_menu" : "")}>
                <HashLink to="/"><img src="/assets/icons/icon_dashboard.svg" alt="" /></HashLink>
              </li>
              <li className={clsx(navId === "profile" ? "selected_menu color2" : "")}>
                <HashLink to="/profile"><img src="/assets/icons/icon_profile.svg" alt="" /></HashLink>
              </li>
          </ul>
          <ul className='back'>
              <li className={clsx(navId === "rewards" ? "selected_menu color1" : "")}>
                <HashLink to="/rewards">{navId === "rewards" ? <img src="/assets/icons/icon_reward_01.svg" alt="" />:<img src="/assets/icons/icon_reward.svg" alt="" />}</HashLink>
              </li>
              <li className={clsx(navId === "inventory" ? "selected_menu color3" : "")}>
                <HashLink to="/inventory"><img src="/assets/icons/icon_inventory.svg" alt="" /></HashLink>
              </li>
              {/* <li className={clsx(navId === "create_project" ? "selected_menu" : "")}>
                <HashLink to="/create_project"><img src="/assets/icons/icon_flag.svg" alt="" /></HashLink>
              </li> */}

              <li className={clsx(navId === "roster" ? "selected_menu" : "")}>
                <HashLink to="/roster"><img src="/assets/icons/icon_chart.svg" alt="" /></HashLink>
              </li>
              
          </ul>
          <ul>
              <li className={clsx(navId === "refresh" ? "selected_menu" : "")}>
                <HashLink to="/refresh"><img src="/assets/icons/icon_refresh.svg" alt="" /></HashLink>
              </li>
          </ul>
        </div>
        <div className={classes.avatar} >
          <div className="img_div">
            <HashLink to="/profile"><img src="/assets/icons/icon_profile.svg" alt="" /></HashLink>
          </div>
        </div>
    </div>
  );
};


export default SideBar;
