import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Topbar from './topbar/Topbar';
import Menu from 'components/Layout/Menu/Menu';
import useStyles from './style';
import SideBar from './SideBar';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles();
  const [navId, setNavId] = useState("");
  const search = useLocation();
  const [backImg, setBackImg] = useState("url('/assets/II3-Dashboard.png')");
  useEffect(() => {
    if(search.hash.includes("#")){
      const hash = search.hash.replace("#", "");
      setNavId(hash);
    }else{
      const hash = search.pathname.replace("/", "");
      setNavId(hash);
      if(hash === '') setBackImg("url('/assets/II3-Dashboard.png')")
      if(hash === 'rewards') setBackImg("url('/assets/reward_bg.png')")
      if(hash === 'roster') setBackImg("url('/assets/roster_bg.png')")
      if(hash === 'inventory') setBackImg("url('/assets/inventory_bg.png')")
      if(hash === 'profile') setBackImg("url('/assets/profile_bg.png')")
    }
  }, [setNavId, search]);
  return (
    <>
      
      <div className={`${classes.layout}`}>
        
        <div className={classes.layoutContent}>
          <div className={classes.sideBar}>
            <SideBar/>
          </div>
          <div className={`${classes.mainContent}`} style = {{backgroundImage : `${backImg}`}}>
          <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            {children}
          </div>
        </div>
        <div className={classes.effect1}></div>
        <div className={classes.effect2}></div>
        <div className={classes.effect3}></div>
        {navId === '' &&
        <div className={classes.effect_group1}>
          <div className="bottom"></div>
          <div className="top"></div>
        </div>}

        {navId === 'rewards' &&
        <div className={classes.effect_group2}>
          <div className="bottom"></div>
          <div className="top"></div>
        </div>}

        {navId === 'roster' &&
        <div className={classes.effect_group3}>
          <div className="bottom"></div>
          <div className="top"></div>
        </div>}

        {navId === 'inventory' &&
        <div className={classes.effect_group4}>
          <div className="bottom"></div>
          <div className="top"></div>
        </div>}

        {navId === 'profile' &&
        <div className={classes.effect_group5}>
          <div className="bottom"></div>
          <div className="top"></div>
        </div>}
        
      </div>
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
