import './connectModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import { connectors } from 'utils/connectors';
import { useConnect } from 'wagmi';
import { toast } from 'react-toastify';

import { connectorLocalStorageKey } from 'utils/connectors';

interface Props {
  showConnectModal: boolean;
  setShowConnectModal?: any;
}


const ConnectModal: React.FC<Props> = ({ showConnectModal, setShowConnectModal }) => {
  const { connect } = useConnect({
    onError(error) {
      console.log('useConnect Error :', error.message);
      toast.error(error.message);
      window.localStorage.setItem(connectorLocalStorageKey, "");
    },
    onSuccess(data) {
      console.log('useConnect Success:');
    },
  });
  
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showConnectModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showConnectModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowConnectModal(false);
    }, 800);
  };

  return (
    <div className={showConnectModal === true ? 'connectModal active' : 'connectModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
            <h1 className="connectWalletTitle">Connect Wallet</h1>

          </div>
          <div className="connectWalletWrapper">
            <p>Please Connect Your Wallet</p>
            {
              connectors.map((entry, index) => (
                <div className="connectModalBtn metaMask" key={index} onClick={() =>{
                  connect({ connector: entry.connectorId });
                  window.localStorage.setItem(connectorLocalStorageKey, entry.key);
                  onClose();              
                }}>
                  <div className="icon">
                    <img src={entry.icon} alt="" />
                    <h2>{entry.title}</h2>
                  </div>
                  <h2><i className="fas fa-arrow-right"></i></h2>
                </div>
              ))
            }
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default ConnectModal;
