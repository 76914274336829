import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height : '100%',
    display: 'flex',
    alignItems : 'center',
    justifyContent : 'space-between',
    flexDirection: 'column',
    backgroundRepeat : 'repeat',
    [theme.breakpoints.down('xs')]: {
    },

  },
  
  logo: {
    width : 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .img_div': {
      width: '100%',
      height : 0,
      position :'relative',
      paddingBottom : '100%',
      borderRadius : '50%',
      overflow : 'hidden',
      marginBottom : 16,
    },
    '& img': {
      width: '100%',
      height : '100%',
      position :'absolute',
      padding : 4,
      top : 0,
      left : 0,
      ovjectFit : 'cover',
    },
    '& h3': {
      color : '#fff',
      fontSize : 20,
      fontWeight : 500,
    },
    '& p': {
      fontSize : 14,
      color : 'rgba(255, 255, 255, 0.64)',
    },
  },
  avatar: {
    width : 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .img_div': {
      width: '100%',
      height : 0,
      position :'relative',
      paddingBottom : '100%',
      borderRadius : '50%',
      overflow : 'hidden',
    },
    '& img': {
      width: '100%',
      height : '100%',
      position :'absolute',
      padding : 4,
      top : 0,
      left : 0,
      ovjectFit : 'cover',
    },
    '& h3': {
      color : '#fff',
      fontSize : 20,
      fontWeight : 500,
    },
    '& p': {
      fontSize : 14,
      color : 'rgba(255, 255, 255, 0.64)',
    },
  },
  navList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop : 200,
    marginBottom : 'auto',
    '& ul': {
      width: '100%',
      display : 'flex',
      flexDirection : 'column',
      padding : 0,
      listStyle : 'none',
      gridArea : 'auto',
      gap : 10,
      marginBottom : 16,
      
      borderRadius : 16,
      '&.back': {
        background : '#5C8AFF11',
      },
      '& li': {
        width: '100%',
        color : '#fff',
        '& a': {
          display : 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width : 40,
          height : 40,
          borderRadius : 16,
          transition : 'all 0.3s ease',
          [theme.breakpoints.down('md')]: {
            fontSize : 12,
            whiteSpace : 'nowrap'
          },
          '&:hover': {
            color : '#888',
            '& img': {
              filter : 'grayscale(1) brightness(0.5)'
            },
          },
        },
        '& img': {
          width: 16,
          height : 16,
          transition : 'all 0.3s ease',
          
        },
      },
      '& .selected_menu': {
        '& a': {
          background: 'radial-gradient(98.75% 98.75% at 3.75% 1.25%, #7351F8 0%, #5039AC 100%)',
          [theme.breakpoints.down('md')]: {
            
          },
        },
        '& img': {
          filter : 'grayscale(1) brightness(2)',
        },
      },
      '& .color1': {
        '& a': {
          background: 'linear-gradient(152.59deg, #6CA3FF 7.11%, #1D6CF1 101.12%) !important',
          [theme.breakpoints.down('md')]: {
          },
        },
      },
      '& .color2': {
        '& a': {
          background: 'radial-gradient(112.5% 112.5% at 6.25% 0%, #4EFFFF 0%, #970DD8 100%) !important',
          [theme.breakpoints.down('md')]: {
          },
        },
      },
      '& .color3': {
        '& a': {
          background: 'radial-gradient(123.02% 244.69% at 96.7% 107.91%, #CA1DA7 0%, #EE703A 100%) !important',
          [theme.breakpoints.down('md')]: {
          },
        },
      }
    },
    
    '& h3': {
      color : '#fff',
      fontSize : 20,
      fontWeight : 500,
    },
    
  },
 
}));
export default useStyles;
