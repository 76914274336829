import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useStyles } from './style';
import { getScore, getClaimAllTimeStamp } from 'utils/contracts';
import { custom_network, formatNum } from 'utils';
import { useAccount, useNetwork } from 'wagmi';
import { fetchSigner } from '@wagmi/core';

interface PropsType {
  item?: any;
  onClick?: any;
  showExpeditions?: any;
  isSelected?: boolean;
}

const NFTCard = ({ item, onClick, showExpeditions, isSelected }: PropsType) => {
  const classes = useStyles();
  const [score, setScore] = useState(0);
  const [claimAllTimeStamp, setClaimAllTimeStamp] = useState(0);


  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();

  const [signer, setSigner] = useState(null);
  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id)) {
      fetchSigner()
        .then((result) => {
          if (result) {
            setSigner(result);
          }
        })
        .catch((error) => {
          console.log('fetch signer error:', error);
          setSigner(null);
        })
    }
  }, [address, isConnected, chain]);

  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id) && signer) {

      getScore(signer)
        .then((result) => {
          if (result) {
            console.log('ClaimAllAmount:', result);
            setScore(result);
          }
        })
        .catch((error) => {
          console.log('claim all amount fetch error:', error);
          setScore(0)
        })
      getClaimAllTimeStamp(signer, address)
        .then((result) => {
          if (result) {
            console.log('ClaimAllAmount:', result);
            setClaimAllTimeStamp(result);
          }
        })
        .catch((error) => {
          console.log('claim all amount fetch error:', error);
          setClaimAllTimeStamp(0)
        })
    }
  }, [address, isConnected, chain, signer]);

  const [totalClaimable, setTotalClaimable] = useState(0);
  useEffect(() => {
    if (score > 0 && item) {
      let earned = 0;
      const currentTimestamp = new Date().getTime();
      if (item.claimAllEnabled) {
        earned += (score * (Math.floor(currentTimestamp / 1000) - claimAllTimeStamp)) / 86400.0;
      }
      earned += (score * (Math.floor(currentTimestamp / 1000) - Number(item.lastClaimed))) / 86400.0;
      setTotalClaimable(earned);

    }
  }, [score, item, claimAllTimeStamp]);

  function getNextlevel(timestamp: any) {
    const currentTimestamp = new Date().getTime();
    const distanceTimeStamp = Math.floor(currentTimestamp / 1000) - Number(timestamp);
    const distanceDate = Math.floor(distanceTimeStamp / 86400);
    var level = 'Wanderer';
    if (distanceDate < 1) {
      level = 'Wanderer';
    } else if (distanceDate < 30) {
      level = 'Adventurer';
    } else if (distanceDate < 90) {
      level = 'Expeditionist';
    } else if (distanceDate < 180) {
      level = 'Navigator';
    } else if (distanceDate < 270) {
      level = 'Seasoned Explorer';
    } else if (distanceDate < 365) {
      level = 'Multiversal Voyager';
    } else {
      level = 'Multiversal Voyager';
    }
    return level;
  }

  function getRemaining(timestamp: any) {
    const currentTimestamp = new Date().getTime();
    const distanceTimeStamp = Math.floor(currentTimestamp / 1000) - Number(timestamp);
    const distanceDate = Math.floor(distanceTimeStamp / 86400);

    var remainingTimeStamp = 0;
    var remaining = '';

    if (distanceDate < 1) {
      remainingTimeStamp = 86400 - distanceTimeStamp;
    } else if (distanceDate < 30) {
      remainingTimeStamp = 86400 * 30 - distanceTimeStamp;
    } else if (distanceDate < 90) {
      remainingTimeStamp = 86400 * 90 - distanceTimeStamp;
    } else if (distanceDate < 180) {
      remainingTimeStamp = 86400 * 180 - distanceTimeStamp;
    } else if (distanceDate < 270) {
      remainingTimeStamp = 86400 * 270 - distanceTimeStamp;
    } else if (distanceDate < 365) {
      remainingTimeStamp = 86400 * 365 - distanceTimeStamp;
    } else {
      remainingTimeStamp = 0;
    }

    if (remainingTimeStamp > 86400) {
      remaining = `${Math.floor(remainingTimeStamp / 86400)} Days`
    } else if (remainingTimeStamp > 3600) {
      remaining = `${Math.floor(remainingTimeStamp / 3600)} Hours`
    } else if (remainingTimeStamp > 60) {
      remaining = `${Math.floor(remainingTimeStamp / 3600)} Mins`
    } else if (remainingTimeStamp > 0) {
      remaining = `${Math.floor(remainingTimeStamp / 3600)} Secs`
    }
    return remaining;
  }

  function getPercent(timestamp: any) {
    const currentTimestamp = new Date().getTime();
    const distanceTimeStamp = Math.floor(currentTimestamp / 1000) - Number(timestamp);
    const distanceDate = Math.floor(distanceTimeStamp / 86400);

    var remainingTimeStamp = 0;
    var percent = 0;

    if (distanceDate < 1) {
      remainingTimeStamp = 86400 - distanceTimeStamp;
      percent = (1 - remainingTimeStamp / 86400) * 100;
    } else if (distanceDate < 30) {
      remainingTimeStamp = 86400 * 30 - distanceTimeStamp;
      percent = (1 - remainingTimeStamp / (86400 * 29)) * 100;
    } else if (distanceDate < 90) {
      remainingTimeStamp = 86400 * 90 - distanceTimeStamp;
      percent = (1 - remainingTimeStamp / (86400 * 60)) * 100;
    } else if (distanceDate < 180) {
      remainingTimeStamp = 86400 * 180 - distanceTimeStamp;
      percent = (1 - remainingTimeStamp / (86400 * 90)) * 100;
    } else if (distanceDate < 270) {
      remainingTimeStamp = 86400 * 270 - distanceTimeStamp;
      percent = (1 - remainingTimeStamp / (86400 * 90)) * 100;
    } else if (distanceDate < 365) {
      remainingTimeStamp = 86400 * 365 - distanceTimeStamp;
      percent = (1 - remainingTimeStamp / (86400 * 95)) * 100;
    } else {
      remainingTimeStamp = 0;
    }
    return percent;
  }


  return (
    <div className={clsx(classes.root, isSelected ? 'selected' : '')}>
      <div className={classes.card_body}>
        <div className={classes.nft_div}>
          <div className='img-div'>
            <img src={`${process.env.REACT_APP_IMG_BASEURL}${item.tokenId}.png`} alt="" />
          </div>
        </div>

        <div className={classes.state}>
          <div className="row">
            <p>#{item.tokenId}</p>
          </div>
          <div className="row">
            <h3>Angry Pitbull</h3>
          </div>
          {/* <div className="row">
            <h4>Expeditions <img src="/assets/icons/icon_warning_01.svg" alt="" onClick={showExpeditions}/></h4>
          </div> */}
          <div className="row">
            <h4>{score} BONE per day</h4>
          </div>
          <div className="progress-div">
            <div className="progress progress1" style={{ width: `${getPercent(item.lastClaimed)}%` }}></div>
          </div>
          <div className="row">
            <h5>{getRemaining(item.lastClaimed)} <span>{getRemaining(item.lastClaimed) == '' ? '' : 'to'} {getNextlevel(item.lastClaimed)}</span></h5>
          </div>
          <div className="row">
            <h5><span>Total Claimable: </span>{formatNum(totalClaimable)} BONE</h5>
          </div>
        </div>
        <button onClick={onClick}>{isSelected ? 'Unselect' : 'Select'}</button>
      </div>
    </div>
  );
};

export default NFTCard;
