// @ts-nocheck
import { useStyles } from './style';
import { useEffect, useState } from 'react';
import { custom_network } from 'utils';
import { useAccount, useNetwork } from 'wagmi';
import { fetchSigner } from '@wagmi/core';
import { toast } from 'react-toastify';
import UploadAvatar from 'components/Forms/UploadAvatar';
import clsx from 'clsx';
import TextInput from 'components/Forms/TextInput';
import Loader from 'components/LoadingPage/Loader';
import { getOwnedTokenIds, getStakedTokenIds } from 'utils/contracts';
import { ethers } from 'ethers';

const ProfilePage = () => {
  const classes = useStyles();
  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const [signer, setSigner] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState('/assets/avatars/avatar_01.png');
  const [allowAddress, setAllowAddress] = useState('Your Address Here.');
  const [username, setUsername] = useState('Your Username Here.');
  const [email, setEmail] = useState('Your Email Here.');
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (address && isConnected && chain && (chain.id === custom_network.id)) {
      fetchSigner()
        .then((result) => {
          if (result) {
            setSigner(result);
            handleSignature(result);
            fetchAndSetAvatar(result, address);

            setAvatarUrl(profileData?.avatarUrl ?? '');
            setAllowAddress(profileData?.allowAddress ?? '');
            setUsername(profileData?.username ?? '');
            setEmail(profileData?.email ?? '');

          }
        })
        .catch((error) => {
          console.log('fetch signer error:', error);
          toast.error('Please connect your wallet.');
        });
    }
  }, [address, isConnected, chain]);

  const handleSignature = async (signer) => {
    const message = "Sign this to confirm that you own this wallet.";
    try {
      const signature = await signer.signMessage(message);
      fetchProfileData(signature, address);
    } catch (error) {
      toast.error('Signature rejected or failed.');
      setProfileData({
        avatarUrl: '/assets/avatars/avatar_01.png',
        allowAddress: 'Sample Address',
        username: 'Sample Username',
        email: 'sample@example.com'
      });
    }
  };

  const fetchAndSetAvatar = async (provider, address) => {
    try {

      const tokenIds = await getOwnedTokenIds(provider, address);
      const tokenIdsStaked = await getStakedTokenIds(provider, address);

      if (tokenIds.length > 0) {
        const randomTokenId = tokenIds[Math.floor(Math.random() * tokenIds.length)];
        setAvatarUrl(`${process.env.REACT_APP_IMG_BASEURL}${randomTokenId}.png`);
      } else if (tokenIdsStaked.length > 0) {
        const randomTokenId = tokenIdsStaked[Math.floor(Math.random() * tokenIdsStaked.length)];
        setAvatarUrl(`${process.env.REACT_APP_IMG_BASEURL}${randomTokenId}.png`);
      } else {
        setAvatarUrl('/assets/avatars/avatar_01.png');
      }
    } catch (error) {
      console.error('Error fetching token IDs:', error);
      setAvatarUrl('/assets/avatars/avatar_01.png');
    }
  };
  const fetchProfileData = async (signature, address) => {
    try {
      const response = await fetch('https://apc-server.vercel.app/api/fetchProfile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ signature, address }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("data fetch", data)

        setProfileData({
          avatarUrl: data.avatarUrl,
          allowAddress: data.allowAddress,
          username: data.username,
          email: data.email
        });

        setAvatarUrl(data.avatarUrl || '/assets/avatars/avatar_01.png');
        setAllowAddress(data.allowAddress);
        setUsername(data.username);
        setEmail(data.email);
        setAllowAddress(data.allowAddress);

        console.log("username of fetch:", (data.username))
        console.log("actual username:", (username))
        setUsername(data.username)
        setEmail(data.email);

      } else {
        throw new Error(data.error || 'Unknown error occurred');
      }
    } catch (error) {
      toast.error(`Error fetching profile: ${error.details.json() || error}`);
    }
  };

  const onChangeAllowAddress = (e) => {
    setAllowAddress(e);
  };

  const onChangeUsername = (e) => {
    setUsername(e);
  };

  const onChangeEmail = (e) => {
    setEmail(e);
  };

  const handleSubmit = async () => {
    if (!signer) {
      toast.error('Signer not available. Please connect your wallet.');
      fetchSigner()
        .then((result) => {
          if (result) {
            setSigner(result);
          }
        })
    }

    const retrieve = false


    const message = "Sign this to confirm that you own this wallet.";
    try {
      const signature = await signer.signMessage(message);
      const response = await fetch('https://apc-server.vercel.app/api/profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signature,
          address,
          profileData: {
            avatarUrl,
            allowAddress,
            username,
            email,
          },
          retrieve
        }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success('Profile updated successfully.');
        console.log("data set", data)
        setProfileData({
          avatarUrl: data.profile.avatarUrl,
          allowAddress: data.profile.allowAddress,
          username: data.profile.username,
          email: data.profile.email
        });

        setAvatarUrl(data.profile.avatarUrl || '/assets/avatars/avatar_01.png');
        setAllowAddress(data.profile.allowAddress || 'Your Address Here.');
        setUsername(data.profile.username);
        setEmail(data.profile.email);
      } else {
        throw new Error(data);
      }
    } catch (error) {
      toast.error(`Error fetching profile: ${error.err.json()}`);
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={classes.root}>
        <div className={`${classes.container}`}>
          <div className={classes.top}>
            <div className={classes.title}>
              <div className="icon">
                <img src="/assets/icons/icon_profile.svg" alt="" />
              </div>
              <h1>Profile</h1>
            </div>
            <p>Configure your profile in this tab</p>
          </div>
          <div className={classes.content}>
            <div className={clsx(classes.row, 'space_between')}>
              <div className="avatar">
                <UploadAvatar dispalyAsset onChange={setAvatarUrl} defaultAsset={avatarUrl} />
                <h3>{username}</h3>
              </div>
              <div className="address">
                <TextInput label={"ETH Wallet Address For Allowlists"} onChangeData={onChangeAllowAddress} value={allowAddress} />
              </div>
            </div>
            <h3 className={classes.sub_title}>CONTACT INFORMATION</h3>
            <div className={clsx(classes.row)}>
              <TextInput label={"Username"} onChangeData={onChangeUsername} value={username} endIcon={<img src="/assets/icons/icon_edit.svg" alt="" />} className='icon' />
              <TextInput label={"E-Mail"} onChangeData={onChangeEmail} value={email} endIcon={<img src="/assets/icons/icon_edit.svg" alt="" />} className='icon' />
            </div>
            <div className={clsx(classes.row)}>
              <div className="btns">
                <button className='submit_btn' onClick={handleSubmit}><p>Submit</p></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;

