import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PopoverMenu from './PopoverMenu';
const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(3.875),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    '& .MuiPopover-paper': {
      background: '#151c36',
      boxShadow: '0 0 26px 10px #1111',
      '&::before': {
        content: "''",
        width: 30,
        height: 30,
        borderRadius: theme.shape.borderRadius,
        background: '#151c36',
        position: 'absolute',
        top: -7,
        left: 'calc(50% - 25px)',
        transform: 'rotate(45deg)',
        zIndex: -1,
        // boxShadow: theme.shadows[10],
      },
      [theme.breakpoints.down('xs')]: {
        '&::before, &::after': {
          display: 'none',
        },
      },
    },
  },
  menuContent: {
    minWidth: 140,
    padding: '10px 20px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '90vw',
    },
  },
  label: {
    flex: 1,
    fontWeight: 700,
    color: '#75849D',
  },
  menus: {
  },
  icon: {
    color: '#00d9ac !important',
    fontSize: 25 + 'px !important',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    color: '#75849D',
    fontSize : 12,
    '& svg': {
      fontSize: 20,
      color: '#75849D',
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
    },
  },
  drop_btn: {
    display: 'flex',
    alignItems: 'center',
    color: '#E2EBFB',
    fontSize : 12,
    fontWeight : 600,
    '& .fas': {
      fontSize: 16,
      color: '#75849D',
      marginLeft: theme.spacing(2),
    },
    '& span': {
      fontSize: 12,
      color: '#75849D',
      opacity : 0.4,
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
    },
  },
}));

const UserDropDown = ({ walletAddress, signOut }) => {
  const classes = useStyles();  
  return (
    <PopoverMenu
      className={classes.paper}
      anchor={<div className={classes.drop_btn}>{walletAddress} <i className="fas fa-chevron-down"></i></div>}
      disableCloseAsSelfClick
    >
      <div className={classes.menuContent}>
        <div className={classes.menus}>
          <div className={classes.menuItem} onClick= {signOut}>
            <ExitToAppIcon />
            <p className={classes.label}>Disconnect</p>
          </div>
        </div>
      </div>
    </PopoverMenu>
  );
};

export default UserDropDown;
