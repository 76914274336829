import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 454,
    borderRadius: 40,
    marginBottom: 40,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'rgba(81, 89, 147, 0.01)',
    border: '1.5px dashed #323655',
    overflow: 'hidden',
    padding: 16,
    transition: 'all 0.3s ease',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width: '100%',
      marginBottom: 16,
    },

    '&:hover': {
      transform: 'translateY(-10px)',
      border: '1.5px solid rgba(81, 89, 147, 0.16)',
      background: 'rgba(81, 89, 147, 0.16)',
    },
    '& .img-div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      paddingBottom: '100%',
      height: 0,
      borderRadius: 32,
      marginBottom: 16,
      [theme.breakpoints.down('xs')]: {
      },

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        borderRadius: 32,
        top: 0,
        left: 0,
      },
      '& .effect_img': {
        filter: 'blur(32px)',
        opacity: 0.7,
        width: '80%',
        height: '80%',
        top: '10%',
        left: '10%',
      },
    },
  },
  card_body: {
    cursor: 'pointer',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
    },
  },
  state: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gridArea: 'auto',
    gap: 16,
    [theme.breakpoints.down('xs')]: {

    },
    '& .row': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& p': {
        color: '#639DFF',
        fontSize: 12,
        fontWeight: 600,
      },
      '& h3': {
        color: '#E2EBFB',
        fontSize: 19,
        fontWeight: 600
      },
      '& h5': {
        color: '#639DFF',
        fontSize: 12,
        fontWeight: 600,        
      },
    },

  },
}));



export default useStyles;
